<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      ตรวจสอบรางวัล
    </div>
    <div class="pa-4" @click="$router.push({ name: 'profile' })" style="cursor: pointer;">
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
    </div>
    <div class="mt-4" v-if="rewards && rewards.length > 0">
      <div v-for="(r, index) in rewards" :key="index" class="pa-2 box-reward">
        <div class="d-flex justify-space-between mb-2">
          <div><b>แคมเปญ</b>&nbsp;&nbsp;&nbsp;{{ r.campaign_name }}</div>
          <div class="gray--text">
            {{ dayjs(r.timestamp).format("DD/MM/YYYY HH:mm") }}
          </div>
        </div>
        <div style="height: 170px" class="d-flex pl-5" v-for="p in r.productdetails" :key="p.id">
          <div style="height: 170px; width: 160px" class="d-flex align-center justify-center">
            <img :src="p.photo" width="140px" height="140px" />
          </div>
          <div class="pl-5 pt-10">
            <div class="pt-5 primary--text">
              <b>{{ p.name }}</b>
            </div>
            <div class="mb-3">
              <b>แลก&nbsp;{{ p.points }}&nbsp;แต้ม</b>
            </div>
          </div>
        </div>
        <div class="mt-5 gray--text pl-7">
          <div>ที่อยู่จัดส่ง&nbsp;:&nbsp;{{ r.address }}</div>
          <div
            :class="{
              approve: r.shipping_status == 'จัดส่งแล้ว',
              waiting: r.shipping_status != 'จัดส่งแล้ว',
            }"
          >
            สถานะ&nbsp;:&nbsp;{{ r.shipping_status || "รอดำเนินการ" }}
          </div>
          <div>เลขพัสดุ&nbsp;:&nbsp;{{ r.tracking_no }}</div>
        </div>
      </div>
    </div>
    <infinite-loading spinner="bubbles" :identifier="infiniteId" @infinite="infiniteHandler">
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
    <div v-if="rewards.length == 0 && IsstateComplete" style="margin-top: 30%; text-align: center">
      <h2 style="color: #979696">ยังไม่มีประวัติการแลกของรางวัล</h2>
    </div>
  </MainLayout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      rewards: [],
      state: null,
      infiniteId: +new Date(),
      IsstateComplete: false,
      limit: 5,
      offset: 0,
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  mounted() {},
  methods: {
    async getData() {
      let data = {
        pageid: this.pageId,
        userid: this.userId,
      };
      let result = await api.GetRewardHistory(data);
      if (result.responsecode == 200 && result.data) {
        this.rewards = result.data;
      }
    },
    async infiniteHandler(state) {
      this.IsstateComplete = false;
      this.state = state;
      var length = await this.getData();
      this.offset += 5;
      if (length) {
        this.state.loaded();
      } else {
        this.state.complete();
        this.IsstateComplete = true;
      }
    },
  },
};
</script>

<style scoped>
.box-reward {
  border: 1px solid #e6e6e6;
  box-shadow: 5px 5px #e6e6e6;
  margin-bottom: 20px;
}
.approve {
  color: #0ab826;
}
.notapprove {
  color: #ff1414;
}
.waiting {
  color: #ffcc4a;
}
</style>
