export default {
    computed: {
        userId() {
            return this.$store.state.userId;
        },
        userData() {
            return this.$store.state.userData;
        },
        pageId(){
            return this.$store.state.pageId;
        }
    }
}