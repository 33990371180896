<template>
  <MainLayout showBottomNav>
    <div class="pa-4 mb-10">
      <div style="cursor: pointer;" @click="$emit('onClose')">
        <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
      </div>

      <div class="ma-5">
        <h4 class="mb-6 primary--text">ใบเสร็จที่เคยแนบไว้</h4>
        <div
          :style="{
            'background-image': 'url(' + showImagePreview + ')',
            'background-size': '100% 100%',
            'background-repeat': 'no-repeat',
          }"
          style="display: flex; justify-content: center; align-items: center; height: 350px;"
          @click="showImageDialog = true"
        >
          <v-icon large>mdi-magnify</v-icon>
        </div>

        <v-row class="mt-3">
          <v-col v-for="n in img" :key="n" class="d-flex child-flex" cols="4">
            <v-img :src="n" aspect-ratio="1" class="grey lighten-2" @click="showImagePreview = n">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="showImageDialog" width="480px">
      <div>
        <v-img @click="showImageDialog = false" :src="showImagePreview">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="primary lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </v-dialog>
  </MainLayout>
</template>

<script>
export default {
  props: {
    img: {
      type: Array,
    },
  },
  mounted() {
    this.showImagePreview = this.img[0];
  },
  data() {
    return {
      showImageDialog: false,
      showImagePreview: "",
    };
  },
  methods: {
    openImage(img) {
      this.showImagePreview = img;
      this.showImageDialog = true;
    },
  },
};
</script>
