import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
import api from '@/api';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pageId: process.env.VUE_APP_PAGE_ID,
        lineProfile: null,
        userId: null,
        userData: {
            firstName: '',
            lastName: '',
            phone: '',
            picture: ''
        },
        register: {
            phone: "",
            firstName: "",
            lastName: "",
            term: false,
            privacy: false,
        },
        cart: [],
        loading: false,
        query: null,
        IsClickReward: false,
        remainingPoints: 0,
        originalPoints: 0,
        requirePoints: 0,
        cartLoading: false
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value;
        },
        setUserData(state, payload) {
            state.userData = payload;
        },
        setLineProfile(state, payload) {
            state.lineProfile = payload;
            state.userId = payload.userId + "__" + state.pageId
        },
        setAddCart(state, payload) {
            state.cart.push(payload);
        },
        editCart(state, payload) {
            Vue.set(state.cart[payload.index], "quantity", payload.quantity);
        },
        deleteCart(state, payload) {
            state.cart.splice(payload.index, 1);
        },
        clearCart(state) {
            state.cart = []
        },
        calculateRemainingPoint(state) {
            let requirePoints = 0;
            if (state.cart && state.cart.length > 0) {
                requirePoints = state.cart
                    .map((m) => m.quantity * m.points)
                    .reduce((prev, curr) => prev + parseInt(curr));
            }
            state.requirePoints = requirePoints;
            state.remainingPoints = state.originalPoints - requirePoints;
        },
        setCart(state, payload) {
            state.cart = payload;
        },
        setCartLoading(state,payload){
            state.cartLoading = payload;
        }
    },
    actions: {
        sendMessage({ state }, message) {
            var url = "https://communitybotbyzwizai.herokuapp.com/zwiz-pushmsg-webbrowser";
            var data = {
                pageid: state.pageId,
                userid: state.userId,
                message,
            };
            if (window.liff.isInClient()) {
                try {
                    window.liff.sendMessages([
                        {
                            text: message,
                            type: "text",
                        },
                    ]);
                } catch (error) {
                    alert(JSON.stringify(error));
                }
            } else {
                axios
                    .post(url, data)
                    .then((response) => {
                        if (response.data.responsecode != 200) {
                            if (response.data.reason) {
                                Swal.fire({
                                    text: response.data.reason,
                                });
                            }
                        }
                    });
            }
        },
        addToCard({ state, commit }, reward) {
            return new Promise((resolve) => {
                (async () => {
                    commit("setCartLoading", true);
                    commit("setLoading", true);
                    reward.productid = reward.id;
                    const index = state.cart.findIndex(f => f.productid == reward.id)
                    if (index != -1) {
                        const item = state.cart[index];
                        if (reward.quantity == 0) {
                            let data = {
                                id: item.id,
                            }
                            let result = await api.DeleteRewardToCart(data)
                            if (result.responsecode == 200) {
                                commit("deleteCart", {
                                    index: index,
                                    quantity: reward.quantity
                                })
                            } else {
                                Swal.fire({
                                    text: result.reason,
                                });
                            }
                        } else {
                            let data = {
                                id: item.id,
                                quantity: reward.quantity,
                                cartprice: reward.points * reward.quantity
                            }
                            let result = await api.EditRewardToCart(data)
                            if (result.responsecode == 200) {
                                commit("editCart", {
                                    index: index,
                                    quantity: reward.quantity
                                })
                            }
                            else if (result.responsecode == 204) {
                                Swal.fire({
                                    text: result.reason,
                                });
                            } else {
                                Swal.fire({
                                    text: result.reason,
                                });
                                window.liff.closeWindow();
                            }

                        }
                    } else {
                        let data = {
                            userid: state.userId,
                            pageid: state.pageId,
                            productid: reward.id,
                            campaign_id: reward.campaign_id,
                            productname: reward.name,
                            quantity: reward.quantity,
                            cartprice: reward.points * reward.quantity
                        }
                        let result = await api.AddRewardToCart(data)
                        if (result.responsecode == 200) {
                            reward.id = result.id;
                            commit("setAddCart", reward)
                        } else if (result.responsecode == 204) {
                            Swal.fire({
                                text: result.reason,
                            });
                        } else {
                            Swal.fire({
                                text: result.reason,
                            });
                            window.liff.closeWindow();
                        }
                    }
                    commit("calculateRemainingPoint")
                    commit("setCartLoading", false);
                    commit("setLoading", false);
                    resolve();

                })()
            })
        },
        setCart({ commit }, reward) {
            commit("setCart", reward);
            commit("calculateRemainingPoint")
        },
    },
    getters: {
        getCart(state) {
            return state.cart;
        }
    }
})