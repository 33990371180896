import api from "@/api";
export default async function auth({ next, store }) {
  if (store.state.userData && store.state.userData.firstName) {
    return next();
  }

  store.commit("setLoading", true);
  let IsValid = await api.getProfile(store.state.userId);
  store.commit("setLoading", false);
  if (!IsValid) {
    window.liff.closeWindow();
    return next({ name: "none" });
  } else {
    return next();
  }
}
