import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import vuetify from './plugins/vuetify'
import sweetalert from "sweetalert2";
import router from './router'
import "./validate/vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CustomInput from '@/components/CustomInput.vue'
import Loading from '@/components/Loading.vue';
import MainLayout from '@/layouts/main.vue'
import commonMixin from '@/mixins/common'
require('dotenv').config();

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('CustomInput', CustomInput)
Vue.component('MainLayout', MainLayout)
Vue.component('Loading', Loading);
Vue.mixin(commonMixin);

Vue.prototype.$swal = sweetalert;
Vue.prototype.$liff = window.liff;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')