import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import UploadReceipt from "../views/UploadReceipt.vue";
import Profile from "../views/Profile.vue";
import Reward from "../views/Reward/RewardList.vue";
import Ecoupon from "../views/Reward/RewardEcoupon.vue";
import PublicEcoupon from "../views/PublicEcoupon.vue";
import RewardTerm from "../views/Reward/RewardTerm.vue";
import ConditionAndTerm from "../views/ConditionAndTerm.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import readPrivacyPolicy from "../views/readPrivacyPolicy";
import None from "../views/NoData.vue";
import Campaign from "../views/Campaign.vue";
import ProductReward from "../views/CollectReward/ProductReward.vue";
import AddressForReward from "../views/AddressForReward.vue";
import RewardHistory from "../views/CollectReward/RewardHistory.vue";
import Personal from "../views/Personal.vue";

//route middleware
import lineAuth from "./middleware/lineAuth";
import zwizAuth from "./middleware/zwizAuth";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: [lineAuth],
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      middleware: [lineAuth],
    },
  },
  {
    path: "/uploadReceipt",
    name: "uploadReceipt",
    component: UploadReceipt,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      middleware: [lineAuth],
    },
  },
  {
    path: "/reward",
    name: "reward",
    component: Reward,
    meta: {
      middleware: [lineAuth],
    },
  },
  {
    path: "/ecoupon",
    name: "ecoupon",
    component: Ecoupon,
    meta: {
      middleware: [lineAuth],
    },
  },
  {
    path: "/public-ecoupon",
    name: "public-ecoupon",
    component: PublicEcoupon,
  },
  {
    path: "/rewardTerm",
    name: "rewardTerm",
    component: RewardTerm,
  },
  {
    path: "/conditionAndTerm",
    name: "conditionAndTerm",
    component: ConditionAndTerm,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/readPrivacyPolicy",
    name: "readPrivacyPolicy",
    component: readPrivacyPolicy,
  },
  {
    path: "/404",
    name: "none",
    component: None,
  },
  {
    path: "/campaign",
    name: "campaign",
    component: Campaign,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "/productReward",
    name: "productReward",
    component: ProductReward,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "/addressForReward",
    name: "addressForReward",
    component: AddressForReward,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "/rewardHistory",
    name: "rewardHistory",
    component: RewardHistory,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "/personal",
    name: "personal",
    component: Personal,
    meta: {
      middleware: [lineAuth, zwizAuth],
    },
  },
  {
    path: "*",
    redirect: { name: "none" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = { to, from, next, store };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
