<template>
  <MainLayout>
    <div class="main-color pa-6 mb-3 white--text" style="position: relative;">
      <div class="text-center">
        ข้อมูลของรางวัล
      </div>
      <div style="position: absolute; top: 15px; right: 20px;">
        <v-badge :content="amountOfReward">
          <v-btn icon @click="showCartDialog = true">
            <v-icon color="white">mdi-cart</v-icon></v-btn
          >
        </v-badge>
      </div>
    </div>
    <div class="pa-4" @click="$emit('onClose')" style="cursor: pointer;">
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
    </div>
    <div v-if="reward">
      <div class="text-center">
        <img :src="reward.photo" width="120px" height="120px" class="mb-2" />
        <div>
          <b>{{ reward.name }}</b>
        </div>
        <div class="primary--text">
          <b>แลก&nbsp;{{ reward.points }}&nbsp;แต้ม</b>
        </div>
      </div>
      <div class="pa-4 mb-4">
        <div style="color: #9C9A9A">รายละเอียดรางวัล</div>
        <div class="black--text">{{ reward.description }}</div>
      </div>
      <div class="d-flex justify-center mb-12">
        <CartButton :reward="reward" v-model="amount" :IsAddToCart="false"></CartButton>
      </div>
      <div class="pa-4">
        <v-btn
          color="main-color"
          class="white--text"
          block
          @click="addReward"
          rounded
          large
          :disabled="amount == 0 || remainingPoints < reward.points"
          >เพิ่มของรางวัล</v-btn
        >
      </div>
    </div>

    <v-dialog
      persistent
      no-click-animation
      v-model="showCartDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <Cart
          v-if="showCartDialog"
          @onClose="
            showCartDialog = false;
            $emit('onClose');
          "
          @onBack="showCartDialog = false"
        ></Cart
      ></v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import CartButton from "@/components/CartButton.vue";
import Cart from "./Cart.vue";
export default {
  props: {
    detail: {
      type: Object,
      default: null,
    },
  },
  components: {
    CartButton,
    Cart,
  },
  data() {
    return {
      loading: false,
      reward: this.detail,
      amount: 0,
      showCartDialog: false,
    };
  },
  computed: {
    cart() {
      return this.$store.getters.getCart;
    },
    amountOfReward() {
      let amount = 0;
      let cart = this.$store.getters.getCart;
      if (cart && cart.length > 0) {
        amount = cart.map((m) => m.quantity).reduce((prev, curr) => prev + parseInt(curr));
      }
      return amount;
    },
    remainingPoints() {
      return this.$store.state.remainingPoints;
    },
  },
  mounted() {
    if (this.reward) {
      if (this.cart && this.cart.length > 0) {
        const index = this.cart.findIndex((f) => f.productid == this.reward.id);
        if (index != -1) {
          this.amount = this.cart[index].quantity;
        }
      }
    }
  },
  methods: {
    async addReward() {
      this.reward.quantity = this.amount;
      await this.$store.dispatch("addToCard", this.reward);
      this.showCartDialog = true;
    },
  },
};
</script>
