<template>
  <MainLayout :showBottomNav="!loading">
    <div v-if="!loading">
      <div class="mx-auto mt-3 text-center">
        <img src="@/assets/logo.png" width="62px" />
      </div>
      <div
        style="
          height: wrap-content;
          border-bottom: 5px solid #013e92 !important;
        "
        class="secondary-color"
      >
        <div class="text-right">
          <v-btn rounded color="white" class="mt-2 mr-2" @click="$router.push({ name: 'reward' })"
            ><v-icon color="yellow">mdi-star</v-icon>&nbsp;{{ userData.points }}&nbsp;แต้ม</v-btn
          >
        </div>
        <div style="width: 150px" class="mx-auto text-center pt-8">
          <template v-if="!showDummyProfilePic">
            <template v-if="lineProfile">
              <img
                :src="lineProfile.pictureUrl"
                alt="userProfile"
                style="border-radius: 50%; width: 120px; height: 120px; z-index: 2"
                @error="showDummyProfilePic = true"
              />
            </template>
            <template v-else-if="userData.picture">
              <img
                :src="userData.picture"
                alt="userProfile"
                style="border-radius: 50%; width: 120px; height: 120px; z-index: 2"
                @error="showDummyProfilePic = true"
              />
            </template>
          </template>
          <template v-else>
            <img
              src="@/assets/user.png"
              alt="userProfile"
              style="border-radius: 50%; width: 120px; height: 120px; z-index: 2"
            />
          </template>
          <div class="mt-4 mb-4 d-flex justify-center">
            <v-btn
              rounded
              color="white"
              class="mr-2"
              @click="
                $router.push({
                  name: 'reward',
                  query: { type: 'ecoupon' },
                })
              "
              ><v-icon color="primary">mdi-gift</v-icon>&nbsp;E-Coupon</v-btn
            >
            <v-btn
              rounded
              color="white"
              @click="
                $router.push({
                  name: 'reward',
                  query: { type: 'receipt' },
                })
              "
              ><v-icon color="primary">mdi-star-circle</v-icon>&nbsp;ประวัติสะสมแต้ม</v-btn
            >
          </div>
        </div>
      </div>
      <div class="ma-4">
        <div class="box d-flex justify-space-between">
          <div class="d-flex">
            <div class="mr-2"><v-icon color="primary">mdi-account</v-icon></div>
            <div>
              <div>ชื่อ-สกุล : {{ userData.firstName + " " + userData.lastName }}</div>
              <div>เบอร์โทรศัพท์ : {{ userData.phone }}</div>
            </div>
          </div>
          <div @click="$router.push({ name: 'personal' })">
            <v-icon color="primary">mdi-pencil</v-icon>
          </div>
        </div>
        <hr />
        <div
          class="box d-flex justify-space-between"
          @click="$router.push({ name: 'rewardHistory' })"
        >
          <div>
            <v-icon color="primary">mdi-history</v-icon>&nbsp;&nbsp;<span
              >ดูประวัติการแลกของรางวัล</span
            >
          </div>
          <div><v-icon color="primary">mdi-chevron-right</v-icon></div>
        </div>
        <hr />
        <br />
        <br />
        <div class="pa-3" style="background: rgb(129, 174, 236); border-radius: 5px">
          <div
            class="primary--text"
            style="font-size: 14px"
            @click="$router.push({ name: 'readPrivacyPolicy' })"
          >
            <u>อ่านนโยบายความเป็นส่วนตัว</u>
          </div>
          <div
            class="primary--text"
            style="font-size: 14px"
            @click="$router.push({ name: 'conditionAndTerm' })"
          >
            <u>อ่านข้อตกลงและเงื่อนไข</u>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      showDummyProfilePic: false,
      loading: false,
    };
  },
  computed: {
    lineProfile() {
      return this.$store.state.lineProfile;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    let { getProfile } = this.$route.params;

    if (!this.userData.firstName || getProfile) {
      await this.getProfile();
    }
  },
  methods: {
    async getProfile() {
      this.loading = true;
      this.$store.commit("setLoading", true);
      let IsValid = await api.getProfile(this.userId);
      if (!IsValid) {
        this.$router.push({ name: "register" });
        this.$liff.closeWindow();
      } else {
        this.$store.commit("setLoading", false);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.box {
  padding: 20px;
}
</style>
