var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',[_c('div',{staticClass:"main-color pa-4 text-center white--text",staticStyle:{"font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.topicHeader)+" ")]),_c('div',{staticClass:"pa-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left")]),_vm._v(" "),_c('span',{staticClass:"primary--text"},[_vm._v("กลับ")])],1),(_vm.selectedCampaign)?_c('div',{staticClass:"text-center primary--text mb-4"},[_c('v-menu',{attrs:{"offset-y":"","attach":"","rounded":"","disabled":_vm.filterCampaigns && _vm.filterCampaigns.length == 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"topic mt-2 mb-5"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedCampaign.name)+" "),(_vm.filterCampaigns && _vm.filterCampaigns.length > 0)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)]}}],null,false,3730431881)},[_c('v-list',_vm._l((_vm.filterCampaigns),function(fc){return _c('v-list-item',{key:fc.id},[_c('v-list-item-title',{on:{"click":function($event){return _vm.onChooseCampaign(fc)}}},[_vm._v(_vm._s(fc.name))])],1)}),1)],1)],1):_vm._e(),(_vm.lists && _vm.lists.length > 0)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.lists),function(d,index){return _c('div',{key:index,staticClass:"pa-5 box-reward"},[_c('div',[_c('p',{staticClass:"text-right",staticStyle:{"color":"gray"}},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.dayjs(d.updated_timestamp).format("DD/MM/YYYY HH:mm")))])]),(_vm.rewardType == 'receipt')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"list-topic"},[_c('b',[_vm._v("เลขที่ใบเสร็จ")])]),_c('div',[_vm._v(_vm._s(d.ref_receipt))])]):_vm._e(),(_vm.rewardType == 'receipt')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"list-topic"},[_c('b',[_vm._v("สถานะ")])]),_c('div',{class:{
              approve: d.status == 'approved',
              notapprove: d.status == 'reject',
              waiting: ['pending', 'waiting_for_delivery'].includes(d.status),
            }},[_vm._v(" "+_vm._s(_vm._f("statusWording")(d.status))+" ")])]):_vm._e(),(d.status == 'reject')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"list-topic"},[_c('b',[_vm._v("สาเหตุ")])]),_c('div',[_vm._v(_vm._s(d.reject_reason))])]):_vm._e()]),(d.status == 'approved')?_c('div',{staticClass:"mt-1"},[(
            _vm.selectedCampaign.type == 'full' ||
              (_vm.selectedCampaign.type == 'collect' && _vm.rewardType == 'ecoupon') ||
              _vm.selectedCampaign.type == 'lucky_draw'
          )?_c('div',{staticClass:"primary--text",staticStyle:{"padding":"15px","background":"rgb(210, 229, 255)","border":"1px solid #013E92","border-radius":"8px"}},[_c('b',[_vm._v("คูปองที่ได้รับ ("+_vm._s(d.coupon.length)+")")]),_c('div',{staticStyle:{"font-size":"12px","color":"gray","margin-bottom":"8px"}},[_vm._v(" คลิกรูปกล่องของรางวัลด้านล่างเพื่อตรวจสอบ ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('input',{attrs:{"type":"checkbox","id":'expend' + index}}),_c('v-layout',{staticClass:"mt-2 mx-auto gift-cover ",staticStyle:{"flex-wrap":"wrap","max-width":"270px"},attrs:{"justify-center":""}},[_vm._l((d.coupon),function(c,cindex){return [_c('v-flex',{key:cindex,staticClass:"px-1",attrs:{"shrink":""}},[_c('v-btn',{staticClass:"gift-box mb-4",class:{
                      'gift-box': !c.is_used,
                      'gift-box-offline': c.is_used,
                    },staticStyle:{"width":"40px","height":"40px"},attrs:{"icon":""},on:{"click":function($event){return _vm.checkEcoupon(c)}}},[_c('v-icon',{class:{
                        'primary--text': !c.is_used,
                        'offline-color': c.is_used,
                      }},[_vm._v("mdi-ticket-confirmation")])],1)],1)]})],2)],1),(d.coupon.length > 5)?_c('div',{staticClass:"text-center"},[_c('label',{attrs:{"for":'expend' + index}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-menu-up")]),_vm._v("ดูเพิ่ม")],1)]):_vm._e()]):(_vm.selectedCampaign.type == 'collect')?_c('div',[_c('div',{staticClass:"d-flex primary--text"},[_c('div',{staticClass:"list-topic"},[_c('b',[_vm._v("แต้มที่ได้รับ")])]),_c('div',[_c('b',[_vm._v(_vm._s(d.score || 0)+" แต้ม")])])])]):_vm._e()]):_vm._e(),(
          (_vm.selectedCampaign.type == 'full' && d.status == 'reject') ||
            (_vm.selectedCampaign.type == 'collect' && _vm.rewardType == 'receipt')
        )?_c('div',{staticClass:"mt-3"},[_c('v-btn',{attrs:{"color":"primary","rounded":"","outlined":"","block":""},on:{"click":function($event){return _vm.openReceipt(d)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-square-edit-outline")]),_vm._v(" ดูใบเสร็จ")],1)],1):_vm._e()])}),0):_vm._e(),_c('infinite-loading',{attrs:{"spinner":"bubbles","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})]),(_vm.lists.length == 0 && _vm.IsstateComplete)?_c('div',{staticStyle:{"margin-top":"30%","text-align":"center"}},[_c('h2',{staticStyle:{"color":"#979696"}},[_vm._v(_vm._s(_vm.noResultMsg))])]):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDetailDialog),callback:function ($$v) {_vm.showDetailDialog=$$v},expression:"showDetailDialog"}},[_c('ReceiptDetail',{attrs:{"img":_vm.imgPreview},on:{"onClose":function($event){_vm.showDetailDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }