export default async function auth({ next, store }) {
  if (store.state.userId) {
    return next();
  }

  store.commit("setLoading", true);
  //   const redirectUri = window.location.href;
  const liffProfile = await initializeLiff();
  if (liffProfile) {
    store.commit("setLineProfile", liffProfile);
  }

  store.commit("setLoading", false);
  return next();
}

const initializeLiff = async () => {
  let intervalCheckLiff = null;
  return new Promise((resolve) => {
    try {
      const liffId = process.env.VUE_APP_LIFFID;
      const liff = window.liff;
      liff.init({ liffId });

      intervalCheckLiff = setInterval(() => {
        if (liff.isLoggedIn()) {
          const liffProfile = liff.getProfile();
          if (liffProfile) {
            window.globalState = {
              liffProfile,
            };
            clearInterval(intervalCheckLiff);
            resolve(liffProfile);
          }
        } else if (!liff.isLoggedIn()) {
          clearInterval(intervalCheckLiff);
          const redirectUri = window.location.href;
          liff.login({ redirectUri });
          resolve(null);
        }
      }, 600);
    } catch (error) {
      console.error(error);
      resolve(null);
    }
  });
};
