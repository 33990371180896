import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
const serverUrl = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTHORIZATION;
axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_KEY;
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  // eslint-disable-next-line no-unused-vars
  (error) => {
    failAlert("เกิดข้อผิดพลาด");
    // return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // trigger 'loading=false' event here
    return response;
  },
  // error
  // eslint-disable-next-line no-unused-vars
  (error) => {
    if (!error.response) {
      failAlert("เกิดข้อผิดพลาด");
    }
  }
);

const failAlert = (reason) => {
  Swal.fire("พบบางอย่างไม่ถูกต้อง", reason, "error");
};

export default {
  async register(data) {
    let result = await axios.post(serverUrl + "/member", data);
    return result.data;
  },
  async getProfile(userId) {
    let result = await axios.get(serverUrl + "/member/" + userId);
    if (result.data.data) {
      store.commit("setUserData", result.data.data);
      return true;
    } else {
      store.commit("setUserData", null);
      return false;
    }
  },
  async uploadReceipt(data) {
    let result = await axios.post(serverUrl + "/receipt", data);
    if (result.data.responsecode == 201) {
      return true;
    } else {
      return false;
    }
  },
  async getReceipts(userId, params) {
    let result = await axios.get(serverUrl + "/receipt/user/" + userId, { params: params });
    return result.data;
  },
  async checkValidEcoupon(data) {
    let result = await axios.get(
      serverUrl + "/ecoupon/validation?userId=" + data.userId + "&barcode=" + data.barcode
    );
    return result.data;
  },
  async useEcoupon(barcode) {
    let result = await axios.put(serverUrl + "/ecoupon/used/" + barcode);
    return result.data;
  },
  async getReceiptByReceiptId(data) {
    let result = await axios.get(serverUrl + "/receipt/" + data.receiptId, {
      params: { userId: data.userId },
    });
    return result.data;
  },
  async CheckRestrictedUpload(data) {
    let result = await axios.get(serverUrl + "/upload/member/" + data.userId, {
      params: { date: data.date },
    });
    return result.data;
  },
  async getCampaigns(data) {
    let result = await axios.get(serverUrl + "/campaign/enabled", { params: data });
    return result.data;
  },
  async getRegisterBackground(data) {
    let result = await axios.get(serverUrl + "/background/register", { params: data });
    return result.data;
  },
  async getReceiptsByCampaignId(userId, params) {
    let result = await axios.get(serverUrl + "/v2/receipt/user/" + userId, { params: params });
    return result.data;
  },
  async getCampaignRewards(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards", { params: data });
    return result.data;
  },
  async getRewardCategoriesByCampaignId(data) {
    let result = await axios.get(serverUrl + "/reward/category/campaign/" + data.campaignId, {
      params: data,
    });
    return result.data;
  },
  async getRewardByCampaignIdAmdCategory(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/products", { params: data });
    return result.data;
  },
  async getPointsByCampaignId(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/member-points-by-campaignid", {
      params: data,
    });
    return result.data;
  },
  async getMyCart(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/carts", { params: data });
    return result.data;
  },
  async AddRewardToCart(data) {
    let result = await axios.post(serverUrl + "/liff/campaignrewards/add-product", data);
    return result.data;
  },
  async EditRewardToCart(data) {
    let result = await axios.put(serverUrl + "/liff/campaignrewards/edit-product", data);
    return result.data;
  },
  async DeleteRewardToCart(data) {
    let result = await axios.delete(serverUrl + "/liff/campaignrewards/remove-product", {
      params: data,
    });
    return result.data;
  },
  async DeleteCart(data) {
    let result = await axios.delete(serverUrl + "/liff/campaignrewards/clear-carts", {
      params: data,
    });
    return result.data;
  },
  async CheckExistingCartByCampaignId(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/check-campaign-in-carts", {
      params: data,
    });
    return result.data;
  },
  async SaveRewardRequest(data) {
    let result = await axios.post(serverUrl + "/liff/campaignrewards/save-rewards", data);
    return result.data;
  },
  async GetRewardHistory(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/history-rewards", {
      params: data,
    });
    return result.data;
  },
  async GetLatestRewardCampaignRequest(data) {
    let result = await axios.get(serverUrl + "/campaignrewards/latest", { params: data });
    return result.data;
  },
  async CheckCampaignParticipationByCampaignIdAndUserId(data) {
    let result = await axios.get(serverUrl + "/receipt/campaign/limitation", { params: data });
    return result.data;
  },
  async CheckOutOfStock(data) {
    let result = await axios.get(serverUrl + "/liff/campaignrewards/carts/product-out-of-stock", {
      params: data,
    });
    return result.data;
  },
  async UpdatePersonalData(data) {
    let result = await axios.put(serverUrl + "/member/" + data.userid, data);
    return result.data;
  },
  async getParticipationCampaign(data) {
    let result = await axios.get(serverUrl + "/campaign/participation", { params: data });
    return result.data;
  },
  async FilloutLuckydrawRewardInfo(data) {
    let result = await axios.post(serverUrl + "/filloutluckydrawrewardinfo", data);
    return result.data;
  },
};
