<template>
  <v-app>
    <v-main>
      <router-view />
      <v-overlay
        :value="IsOverlay"
        color="#ffffff"
        opacity="0.8"
        z-index="200001"
      >
        <div>
          <img src="@/assets/logo.png" width="95px" />
        </div>
        <div>
          <v-progress-linear
            indeterminate
            color="primary"
            height="10"
            striped
          ></v-progress-linear>
        </div>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    IsOverlay() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style>
.Kanit {
  font-family: "K2D", sans-serif !important;
}
/* rgba(232, 58, 8, 1) 0%,
    rgba(232, 58, 8, 1) 50%,
    rgba(232, 131, 59, 1) 100% */

.main-color {
  background: rgb(1, 62, 146) !important;
  background: linear-gradient(
    180deg,
    rgba(1, 62, 146, 1) 0%,
    rgba(6, 81, 184, 1) 50%,
    rgba(59, 129, 225, 1) 100%
  ) !important;
}

/* rgba(232, 58, 8, 1) 0%,
    rgba(238, 96, 22, 1) 50%,
    rgba(232, 131, 59, 0.47) 100% */

.secondary-color {
  background: rgb(1, 62, 146) !important;
  background: linear-gradient(
    180deg,
    rgba(1, 62, 146, 1) 0%,
    rgba(6, 81, 184, 1) 50%,
    rgba(59, 129, 225, 0.47) 100%
  ) !important;
}

/* rgba(242, 148, 117, 1) 0%,
    rgba(245, 162, 123, 1) 50%,
    rgba(242, 178, 138, 1) 100% */

.thrid-color {
  background: rgb(34, 108, 212)!important;
  background: linear-gradient(
    180deg,
    rgb(129, 174, 236) 0%,
    rgb(160, 185, 219) 50%,
    rgb(210, 229, 255) 100%
  ) !important;
  color: white;
}
</style>
