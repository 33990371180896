<template>
  <MainLayout showBottomNav>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      แก้ไขข้อมูลส่วนตัว
    </div>
    <div
      class="pa-4"
      style="cursor: pointer;"
      @click="$router.push({ name: 'profile', params: { getProfile: true } })"
    >
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
    </div>
    <div class="pa-7">
      <ValidationObserver ref="observer">
        <ValidationProvider
          :rules="{
            required: true,
          }"
          name="ชื่อจริง"
          v-slot="{ errors }"
        >
          <custom-input
            v-model="firstName"
            prepend-inner-icon="mdi-account"
            placeholder="ชื่อจริง"
            rounded
            solo
            dense
            outlined
            :error-messages="errors"
          ></custom-input>
        </ValidationProvider>
        <ValidationProvider
          :rules="{
            required: true,
          }"
          name="นามสกุล"
          v-slot="{ errors }"
        >
          <custom-input
            v-model="lastName"
            prepend-inner-icon="mdi-account"
            placeholder="นามสกุล"
            rounded
            solo
            dense
            outlined
            :error-messages="errors"
          ></custom-input>
        </ValidationProvider>
        <ValidationProvider
          :rules="{
            required: true,
            thai_phonenumber: true,
            digits: 10,
            regex: /^[0][3-9][0-9]{4}[0-9]{4}$/,
          }"
          name="เบอร์โทรศัพท์"
          v-slot="{ errors }"
        >
          <custom-input
            placeholder="เบอร์โทร"
            type="tel"
            v-model="phone"
            maxlength="10"
            dense
            rounded
            solo
            prepend-inner-icon="mdi-phone-in-talk-outline"
            numberOnly
            outlined
            :error-messages="errors"
          ></custom-input>
        </ValidationProvider>
      </ValidationObserver>

      <v-btn color="main-color" class="white--text mt-4" block @click="onSubmit" rounded large
        >บันทึก</v-btn
      >
    </div>

    <v-dialog v-model="showDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px"
              >mdi-check-circle</v-icon
            >
            <h3 class="mb-4">แก้ไขข้อมูลสำเร็จ</h3>
            <v-btn
              color="main-color"
              class="white--text"
              block
              @click="showDialog = false"
              rounded
              large
              >ปิด</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDuplicateDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px"
              >mdi-alert-circle</v-icon
            >
            <h3 class="mb-4">พบข้อมูลซ้ำ</h3>
            <v-btn
              color="main-color"
              class="mb-2 white--text"
              block
              @click="showDuplicateDialog = false"
              rounded
              large
              >ปิด</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      showDialog: false,
      showDuplicateDialog: false,
    };
  },
  mounted() {
    this.firstName = this.userData.firstName;
    this.lastName = this.userData.lastName;
    this.phone = this.userData.phone;
  },
  methods: {
    async onSubmit() {
      this.$store.commit("setLoading", true);
      let data = {
        pageid: this.pageId,
        userid: this.userId,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
      };
      let result = await api.UpdatePersonalData(data);
      if (result.responsecode == 200 && result.status) {
        this.showDialog = true;
      } else {
        this.showDuplicateDialog = true;
      }
      this.$store.commit("setLoading", false);
    },
  },
};
</script>
