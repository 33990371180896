<template>
  <MainLayout>
    <div
      class="pa-4 text-center white--text main-color"
      style="font-size: 22px"
    >
      นโยบายความเป็นส่วนตัว
    </div>
    <div class="pa-4" @click="$router.go(-1)" style="cursor: pointer">
      <div>
        <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span
          class="primary--text"
          >กลับ</span
        >
      </div>
    </div>
    <div
      class="ma-2 pa-4"
      style="background-color: #eeeeee; border-radius: 5px"
    >
      <div class="text-center">
        <b
          >นโยบายความเป็นส่วนตัวสำหรับลูกค้า ผู้เข้าร่วมกิจกรรม ของบริษัท ทีโอเอ
          เพ้นท์ (ประเทศไทย) จำกัด (มหาชน)</b
        >
      </div>
      <ul class="topic">
        <li>
          <b>1. บทนำ</b>
          <ul>
            <li>
              บริษัท ทีโอเอ เพ้นท์ (ประเทศไทย) จำกัด (มหาชน) (เรียกว่า “บริษัทฯ
              ”) และบริษัทย่อย เคารพสิทธิความเป็นส่วนตัวของลูกค้า
              ผู้เข้าร่วมกิจกรรม (ต่อไปนี้เรียกว่า “ท่าน”)
              และเพื่อให้เกิดความมั่นใจว่าท่านได้รับความคุ้มครองข้อมูลส่วนบุคคล
              จึงได้จัดทำนโยบายความเป็นส่วนตัวฉบับนี้ขึ้น
              เพื่อแจ้งให้ท่านทราบถึงรายละเอียดที่เกี่ยวข้องกับการเก็บรวบรวม
              การใช้ และการเปิดเผย (รวมเรียกว่า “การประมวลผล”)
              ข้อมูลส่วนบุคคลของท่าน ทั้งออนไลน์และออฟไลน์
              ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคล กำหนด ดังนี้
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>2. วัตถุประสงค์</b>
          <ul>
            <li>
              (1)
              เพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญาหรือเพื่อปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญากับบริษัทฯ
              เช่น เพื่อการขายสินค้า และ/หรือ บริการให้แก่ท่าน
              หรือปฏิบัติตามสัญญาใด ๆ ซึ่งท่านเป็นคู่สัญญา ซึ่งรวมถึง การจัดการ
              account ของท่าน จัดส่ง การดำเนินการเกี่ยวกับบัญชีและการเงิน
              การบริการหลังการขาย และการคืนสินค้า และดำเนินการใด ๆ
              เพื่อให้ท่านได้รับสินค้าและ/หรือ บริการ หรือตามที่ท่านได้ร้องขอ
              ตลอดจนกรณีที่ท่านได้เข้าร่วมกิจกรรมการตลาดต่างๆกับบริษัท เช่น
              การประกวด แข่งขัน ชิงรางวัล การสะสมคะแนนเพื่อแลก และ/หรือชิงรางวัล
              บริษัทจะใช้ข้อมูลของท่านเพื่อเป็นข้อมูลประกอบการร่วมกิจกรรม
              และการพิจารณา ตัดสิน และเป็นการยืนยันตัวตนในการแลก รับ ของรางวัล
            </li>
            <br />
            <li>
              บริษัทจะดูแลให้พนักงานที่ได้รับมอบหมายในเรื่องนี้โดยเฉพาะ มิให้
              ใช้ หรือเปิดเผย แสดง
              หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่าน
              นอกเหนือไปจากวัตถุประสงค์
              ภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้เปิดเผยได้ตามขอบเขตที่ท่านได้ให้ความยินยอม
              หรือขอบเขตที่เกี่ยวข้องในนโยบายฉบับนี้
              ในกรณีที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลหรือนิติบุคคลภายนอก
              บุคคลหรือนิติบุคคลภายนอกนั้นจะต้องแจ้งวัตถุประสงค์ในการ ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้บริษัททราบก่อน
              และต้องไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคล
              นอกเหนือจากวัตถุประสงค์ที่ได้ให้ไว้แก่บริษัท
            </li>
            <br />
            <li>
              (2) เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือของบุคคลอื่น
              <!-- <ul>
                <li>
                  (1) เพื่อให้บริษัทฯ สามารถจัดการ พัฒนา และดำเนินการใด ๆ
                  เพื่อให้สามารถดำเนินธุรกิจได้ ซึ่งรวมถึง การบริหารจัดการและพัฒนาสินค้า และ/หรือ
                  บริการ (รวมถึงเว็บไซต์ และแอปพลิเคชัน) การตรวจสอบและป้องกันการฉ้อโกง
                  หรืออาชญากรรมอื่น ๆ การจัดการความสัมพันธ์ลูกค้าและผู้ที่อาจเป็นลูกค้า
                  การบำรุงรักษาและการใช้ระบบไอที
                </li>
                <li>
                  (2) เพื่อประโยชน์ทางด้านความปลอดภัย เช่น จัดให้มีมาตรการรักษาความปลอดภัย
                  ซึ่งรวมถึงข้อมูลส่วนบุคคลของท่านด้วย เช่น การเข้าสถานที่ของบริษัทฯ การ Log in
                  เข้าเว็บไซต์หรือแอปพลิเคชัน
                </li>
                <li>
                  (3) เพื่อดำเนินการทางการตลาด และวิเคราะห์ข้อมูล (Marketing and Data Analysis) เช่น
                  การแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทางอีเมล เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย
                  โทรศัพท์รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม เข้าสัมภาษณ์
                </li>
                <li>
                  (4) เพื่อให้บริษัท บันทึกภาพทั้งภาพนิ่งและภาพเคลื่อนไหว เสียง บทสัมภาษณ์ คำพูด
                  และยินยอมให้บริษัท เผยแพร่ข้อมูลส่วนบุคคล
                  ที่บริษัทได้บันทึกไว้ในการจัดกิจกรรมที่จัดขึ้นโดยบริษัทหรือที่บริษัทเป็นสปอนเซอร์
                  บนเว็บไซต์ สื่อ โซเชียลมีเดีย ภาพข่าวรวมถึงสื่อสาธารณะต่างๆ
                  โดยมีวัตถุประสงค์เพื่อประโยชน์ทางการค้า และผลิตภัณฑ์ของบริษัท
                  รวมทั้งกิจกรรมอื่นๆที่เป็นการสื่อสารสู่สังคม
                  และเพื่อประโยชน์ต่อการพัฒนากิจกรรมของโครงการดังกล่าวในระยะยาว
                </li>
                <li>(5) เพื่อการใช้สิทธิเรียกร้องทางกฎหมายที่เกี่ยวข้องกับบริษัทฯ และ/หรือ ท่าน</li>
              </ul> -->
            </li>
            <li>
              (3) เพื่อให้บริษัทฯ สามารถจัดการ พัฒนา และดำเนินการใด ๆ
              เพื่อให้สามารถดำเนินธุรกิจได้ ซึ่งรวมถึง
              การบริหารจัดการและพัฒนาสินค้า และ/หรือ บริการ (รวมถึงเว็บไซต์
              และแอปพลิเคชัน) การตรวจสอบและป้องกันการฉ้อโกง หรืออาชญากรรมอื่น ๆ
              การจัดการความสัมพันธ์ลูกค้าและผู้ที่อาจเป็นลูกค้า
              การบำรุงรักษาและการใช้ระบบไอที
            </li>
            <li>
              (4) เพื่อประโยชน์ทางด้านความปลอดภัย เช่น
              จัดให้มีมาตรการรักษาความปลอดภัย
              ซึ่งรวมถึงข้อมูลส่วนบุคคลของท่านด้วย เช่น การเข้าสถานที่ของบริษัทฯ
              การ Log in เข้าเว็บไซต์หรือแอปพลิเคชัน
            </li>
            <li>
              (5) เพื่อดำเนินการทางการตลาด และวิเคราะห์ข้อมูล (Marketing and
              Data Analysis) เช่น การแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทางอีเมล
              เอสเอ็มเอส แอปพลิเคชัน โซเชียลมีเดีย
              โทรศัพท์รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม
              เข้าสัมภาษณ์
            </li>
            <li>
              (6) เพื่อให้บริษัท บันทึกภาพทั้งภาพนิ่งและภาพเคลื่อนไหว เสียง
              บทสัมภาษณ์ คำพูด และยินยอมให้บริษัท เผยแพร่ข้อมูลส่วนบุคคล
              ที่บริษัทได้บันทึกไว้ในการจัดกิจกรรมที่จัดขึ้นโดยบริษัทหรือที่บริษัทเป็นสปอนเซอร์
              บนเว็บไซต์ สื่อ โซเชียลมีเดีย ภาพข่าวรวมถึงสื่อสาธารณะต่างๆ
              โดยมีวัตถุประสงค์เพื่อประโยชน์ทางการค้า และผลิตภัณฑ์ของบริษัท
              รวมทั้งกิจกรรมอื่นๆที่เป็นการสื่อสารสู่สังคม
              และเพื่อประโยชน์ต่อการพัฒนากิจกรรมของโครงการดังกล่าวในระยะยาว
            </li>
            <li>
              (7) เพื่อการใช้สิทธิเรียกร้องทางกฎหมายที่เกี่ยวข้องกับบริษัทฯ
              และ/หรือ ท่าน
            </li>
            <li>
              (8) เพื่อป้องกันและระงับอันตรายต่อชีวิต ร่างกาย
              หรือสุขภาพของท่านหรือบุคคลอื่นเช่น การติดต่อในกรณีฉุกเฉิน
              การควบคุมและป้องกันโรคติดต่อ
            </li>
            <li>
              (9) เพื่อให้สามารถปฏิบัติตามกฎหมาย เช่น
              การปฏิบัติตามบทบัญญัติของกฎหมาย กฏระเบียบ
              และคำสั่งของผู้ที่มีอำนาจตามกฎหมาย
            </li>
            <li>
              (10)
              เพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ
              หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้
            </li>
            <li>กรณีที่ท่านให้ความยินยอมบริษัทฯ จะประมวลผลข้อมูลส่วนบุคคล</li>
            <li>
              (11) เพื่อให้บริษัทในกลุ่มบริษัทฯ
              ที่ไม่ใช่ผู้ขายสินค้าและ/บริการท่านมาก่อนสามารถติดต่อสื่อสารกับท่าน
              เพื่อการแจ้งข่าวสารและสิทธิประโยชน์ ผ่านทาง อีเมล เอสเอ็มเอส
              แอปพลิเคชัน โซเชียลมีเดีย โทรศัพท์
              รวมถึงเพื่อประโยชน์ในการวิจัยทางการตลาด เช่น ทำแบบสอบถาม
              เข้าสัมภาษณ์ เพื่อใช้ในการวิเคราะห์พฤติกรรมการบริโภคของลูกค้า
              เพื่อนำไปพัฒนาโปรแกรม หรือโปรโมชั่นทางการตลาด
            </li>
            <li>
              (12) เพื่อให้บริษัทฯ ดำเนินการจัดกิจกรรมนอกจากที่กล่าวมาข้างต้น
              โดยอาจเก็บข้อมูลส่วนบุคคลของท่านเพิ่มเติม
              โดยจะแจ้งให้ทราบและขอความยินยอมจากท่านใหม่เป็นคราว ๆ ไป
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>3. ขอบเขต</b>
          <ul>
            <li>
              นโยบายฉบับนี้ให้มีผลบังคับใช้กับทุกการดำเนินงาน รวมถึงผู้บริหาร
              และพนักงานทุกคนของบริษัทฯ และบริษัทย่อย
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>4. ข้อมูลส่วนบุคคลที่เก็บรวบรวม</b>
          <ul>
            <li>
              ท่านที่ประสงค์จะใช้บริการบางประเภทที่ดำเนินการผ่านเว็บไซต์นี้
              อาจต้องให้ข้อมูลส่วนบุคคลของท่านที่จำเป็นต่อการให้บริการดังกล่าว
              ตัวอย่างของกำรดำเนินกำรที่ผู้ใช้บริการต้องแจ้งข้อมูลส่วนบุคคล เช่น
              เมื่อผู้ใช้ประสงค์จะยื่นขอสมัครระบบ บริการ
              หรือธุรกรรมอื่นใดที่อาจมีขึ้นในอนาคต เป็นต้น
              บริษัทอาจเก็บรวบรวมข้อมูลใดๆ
              ของท่านซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
              ที่ท่านได้ให้ไว้กับบริษัท โดยการลงทะเบียนเข้าร่วมกิจกรรมต่างๆ
              ของบริษัท คุกกี้ ข้อมูลการทำรายการ
              และประสบการณ์การใช้งานผ่านหน้าเว็บไซต์
              หรือที่ท่านได้อนุญาตให้บริษัทได้เข้าถึง (“ข้อมูลส่วนบุคคล”)
              โดยรวมถึงข้อมูลดังต่อไปนี้ด้วย
            </li>
            <li>
              (1) เมื่อท่านจะซื้อสินค้า และ/หรือ บริการ
              รวมทั้งการเข้าเป็นสมาชิกเว็บไซต์ แอปพลิเคชัน
              หรือบัตรสิทธิประโยชน์ต่าง ๆ เช่น เว็บแอพลิเคชั่น Line OA TOA Paint
              บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
              <!-- <ul>
                <li>
                  (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด เพศ สถานภาพ ที่อยู่
                  ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน Facebook ID Line บัตรประชาชน Passport
                  รูปถ่ายบ้าน แบบบ้าน ประวัติการสนทนา ประวัติสุขภาพ
                  (เฉพาะกรณีที่ท่านใช้บริการหรือผลิตภัณฑ์ที่อาจมีความเกี่ยวพันกับสุขภาพ)
                </li>
                <li>
                  (2) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่ ช่องทางติดต่อในโซเชียลมีเดีย
                  สถานที่ทำงาน
                </li>
                <li>(3) ข้อมูลเกี่ยวกับงานของท่าน เช่น อาชีพ ตำแหน่ง ประสบการณ์การทำงาน</li>
                <li>
                  (4) ข้อมูลเกี่ยวกับการซื้อสินค้า และ/หรือ บริการ เช่น ประเภทสินค้าที่ท่านซื้อ
                  ประวัติการซื้อสินค้า ประวัติการเคลมสินค้า ข้อร้องเรียน ใบเสร็จรับเงิน
                  หรือหลักฐานการชำระราคาสินค้าที่เกี่ยวข้อง เป็นต้น
                </li>
                <li>
                  (5) ข้อมูลที่ท่านได้ให้ไว้แก่บริษัท บริษัทในเครือ เมื่อท่านติดต่อ และ/หรือ
                  ร่วมกิจกรรมต่าง ๆ กับบริษัท บริษัทในเครือ เมื่อบริษัท บริษัทในเครือบริษัทฯ
                  เข้าพบท่าน ดูแลให้บริการหลังการขาย การทำวิจัย และการสัมภาษณ์
                </li>
              </ul> -->
            </li>
            <li>
              (2) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด เพศ สถานภาพ
              ที่อยู่ ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชน Facebook ID Line
              บัตรประชาชน Passport รูปถ่ายบ้าน แบบบ้าน ประวัติการสนทนา
              ประวัติสุขภาพ
              (เฉพาะกรณีที่ท่านใช้บริการหรือผลิตภัณฑ์ที่อาจมีความเกี่ยวพันกับสุขภาพ)
              <!-- <ul>
                <li>
                  (1) ข้อมูลเกี่ยวกับการสมัครสมาชิก (Registration) เช่น ชื่อ นามสกุล อีเมล
                  เบอร์มือถือ รหัสผ่าน
                </li>
                <li>
                  (2) ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP Address หรือ other
                  device identifier
                </li>
                <li>
                  (3) ชนิดและเวอร์ชันของบราวเซอร์ที่ท่านใช้ รวมถึงชนิดและเวอร์ชันของ plug-in
                  ของบราวเซอร์
                </li>
                <li>(4) การตั้งค่าเขตเวลา (Time zone)</li>
              </ul> -->
            </li>
            <li>
              (3) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่
              ช่องทางติดต่อในโซเชียลมีเดีย สถานที่ทำงาน
              <!-- <ul>
                <li>
                  (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด รูปถ่าย หมายเลขบัตรประชาชน
                  หมายเลขใบขับขี่ หมายเลขหนังสือเดินทาง ข้อมูลสุขภาพ
                </li>
                <li>(2) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่</li>
                <li>(3) ข้อมูลเกี่ยวกับการร่วมกิจกรรม เช่น ประวัติการร่วมกิจกรรมในครั้งก่อน ๆ</li>
              </ul> -->
            </li>
            <li>
              (4) ข้อมูลเกี่ยวกับงานของท่าน เช่น อาชีพ ตำแหน่ง
              ประสบการณ์การทำงาน
              <!-- <ul>
                <li>
                  (1) เมื่อบริษัทฯ
                  จำเป็นต้องใช้ข้อมูลดังกล่าวเพื่อประโยชน์ในการรักษาความปลอดภัยของบริษัทฯ เช่น
                  ข้อมูลชีวภาพ (facial recognition ลายนิ้วมือ) เพื่อใช้ในการระบุตัวตน
                </li>
                <li>
                  (2) ในบางกรณี บริษัทฯ อาจเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
                  แม้ว่าสินค้าหรือบริการนั้นไม่ได้เกี่ยวข้องกับข้อมูลชนิดพิเศษโดยตรง เช่น บริษัทฯ
                  จำเป็นต้องใช้บัตรประชาชนของท่าน ซึ่งมีข้อมูลศาสนา เพื่อใช้ในการยืนยันตัวตนของท่าน
                  หรือดำเนินการทางภาษี
                </li>
                <li>(3) ข้อมูลสุขภาพ เช่น ข้อมูลการแพ้อาหาร เพื่อใช้ในการดำเนินกิจกรรมต่าง ๆ</li>
              </ul> -->
            </li>
            <li>
              (5) ข้อมูลเกี่ยวกับการซื้อสินค้า และ/หรือ บริการ เช่น
              ประเภทสินค้าที่ท่านซื้อ ประวัติการซื้อสินค้า ประวัติการเคลมสินค้า
              ข้อร้องเรียน ใบเสร็จรับเงิน
              หรือหลักฐานการชำระราคาสินค้าที่เกี่ยวข้อง เป็นต้น
            </li>
            <li>
              (6) ข้อมูลที่ท่านได้ให้ไว้แก่บริษัท บริษัทในเครือ เมื่อท่านติดต่อ
              และ/หรือ ร่วมกิจกรรมต่าง ๆ กับบริษัท บริษัทในเครือ เมื่อบริษัท
              บริษัทในเครือบริษัทฯ เข้าพบท่าน ดูแลให้บริการหลังการขาย การทำวิจัย
              และการสัมภาษณ์
            </li>
            <li>
              (7) เมื่อท่านเข้าชม และ/หรือ ซื้อสินค้าออนไลน์
              หรือเข้าชมเว็บไซต์หรือแอปพลิเคชัน บริษัทฯ
              จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
              <ul>
                <li>
                  7.1 ข้อมูลเกี่ยวกับการสมัครสมาชิก (Registration) เช่น ชื่อ
                  นามสกุล อีเมล เบอร์มือถือ รหัสผ่าน
                </li>
                <li>
                  7.2 ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP
                  Address หรือ other device identifier
                </li>
                <li>
                  7.3 ชนิดและเวอร์ชันของบราวเซอร์ที่ท่านใช้
                  รวมถึงชนิดและเวอร์ชันของ plug-in ของบราวเซอร์
                </li>
                <li>7.4 การตั้งค่าเขตเวลา (Time zone)</li>
              </ul>
            </li>
            <li>
              (8) เมื่อท่านติดต่อบริษัทฯ หรือร่วมกิจกรรมใด ๆ กับบริษัทฯ บริษัทฯ
              จะเก็บข้อมูลส่วนบุคคล เช่น ติดต่อ Contact Center การทำ Customer
              Satisfaction กิจกรรมสิทธิประโยชน์ต่าง ๆ
              <ul>
                <li>
                  8.1 ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล วันเดือนปีที่เกิด รูปถ่าย
                  หมายเลขบัตรประชาชน หมายเลขใบขับขี่ หมายเลขหนังสือเดินทาง
                  ข้อมูลสุขภาพ
                </li>
                <li>8.2 ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ ที่อยู่</li>
                <li>
                  8.3 ข้อมูลเกี่ยวกับการร่วมกิจกรรม เช่น
                  ประวัติการร่วมกิจกรรมในครั้งก่อน ๆ
                </li>
              </ul>
            </li>
            <li>
              (9) บริษัทฯ
              อาจมีความจำเป็นต้องเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
              เพื่อใช้งานตามวัตถุประสงค์ที่บริษัทฯ
              แจ้งไว้ในนโยบายความเป็นส่วนตัวนี้ เช่น
            </li>
            <li>
              (10) เมื่อบริษัทฯ
              จำเป็นต้องใช้ข้อมูลดังกล่าวเพื่อประโยชน์ในการรักษาความปลอดภัยของบริษัทฯ
              เช่น ข้อมูลชีวภาพ (facial recognition ลายนิ้วมือ)
              เพื่อใช้ในการระบุตัวตน
            </li>
            <li>
              (11) ในบางกรณี บริษัทฯ
              อาจเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
              แม้ว่าสินค้าหรือบริการนั้นไม่ได้เกี่ยวข้องกับข้อมูลชนิดพิเศษโดยตรง
              เช่น บริษัทฯ จำเป็นต้องใช้บัตรประชาชนของท่าน ซึ่งมีข้อมูลศาสนา
              เพื่อใช้ในการยืนยันตัวตนของท่าน หรือดำเนินการทางภาษี
            </li>
            <li>
              (12) ข้อมูลสุขภาพ เช่น ข้อมูลการแพ้อาหาร
              เพื่อใช้ในการดำเนินกิจกรรมต่าง ๆ
            </li>
            <li>
              (13) ในกรณีที่จำเป็น บริษัทฯ
              จะประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านโดยได้รับความยินยอมโดยชัดแจ้งจากท่านหรือเพื่อวัตถุประสงค์อื่นตามที่กฎหมายกำหนดไว้เท่านั้น
              ทั้งนี้ บริษัทฯ
              จะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการรักษาความปลอดภัยที่เพียงพอเพื่อปกป้องคุ้มครองข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>5. ข้อมูลส่วนบุคคลที่มีความอ่อนไหว</b>
          <ul>
            <li>
              (1) บริษัทฯ
              อาจมีความจำเป็นต้องเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านเพื่อใช้งานตามวัตถุประสงค์ที่บริษัทฯ
              แจ้งไว้ในนโยบายความเป็นส่วนตัวนี้
            </li>
            <li>
              (2) บริษัทฯ อาจต้องประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
              ในกรณีดังต่อไปนี้
              <!-- <ul>
                <li>
                  (1) ข้อมูลชีวภาพ (biometric data) เช่น ข้อมูลจำลองลายนิ้วมือ ข้อมูลภาพจำลองใบหน้า
                  เพื่อใช้ในการระบุและยืนยันตัวตนของท่าน การป้องกันอาชญากรรม
                  และการรักษาประโยชน์โดยชอบด้วยกฎหมายของ บริษัทฯ หรือของบุคคลอื่น
                </li>
                <li>
                  (2) ความเชื่อในลัทธิศาสนา ปรัชญา เชื้อชาติ สัญชาติ ความพิการ ข้อมูลพันธุกรรม
                  ข้อมูลชีวภาพ
                </li>
                <li>
                  (3) ข้อมูลส่วนบุคคลที่มีความอ่อนไหวอื่น ๆ ตามวัตถุประสงค์อันชอบด้วยกฎหมาย เช่น
                  เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                  หรือสุขภาพของบุคคลในกรณีที่ท่านไม่สามารถให้ความยินยอมได้
                  เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของท่าน
                  เพื่อใช้สิทธิเรียกร้องตามกฎหมาย เพื่อบรรลุวัตถุประสงค์เกี่ยวกับการคุ้มครองแรงงาน
                  การประกันสังคม และสวัสดิการของพนักงาน
                </li>
              </ul> -->
            </li>
            <li>
              (3) ข้อมูลชีวภาพ (biometric data) เช่น ข้อมูลจำลองลายนิ้วมือ
              ข้อมูลภาพจำลองใบหน้า เพื่อใช้ในการระบุและยืนยันตัวตนของท่าน
              การป้องกันอาชญากรรม และการรักษาประโยชน์โดยชอบด้วยกฎหมายของ บริษัทฯ
              หรือของบุคคลอื่น
            </li>
            <li>
              (4) ความเชื่อในลัทธิศาสนา ปรัชญา เชื้อชาติ สัญชาติ ความพิการ
              ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
            </li>
            <li>
              (5) ข้อมูลส่วนบุคคลที่มีความอ่อนไหวอื่น ๆ
              ตามวัตถุประสงค์อันชอบด้วยกฎหมาย เช่น
              เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
              หรือสุขภาพของบุคคลในกรณีที่ท่านไม่สามารถให้ความยินยอมได้
              เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของท่าน
              เพื่อใช้สิทธิเรียกร้องตามกฎหมาย
              เพื่อบรรลุวัตถุประสงค์เกี่ยวกับการคุ้มครองแรงงาน การประกันสังคม
              และสวัสดิการของพนักงาน
            </li>
            <li>
              (6) ในกรณีที่จำเป็น บริษัทฯ
              จะประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่านโดยได้รับความยินยอมโดยชัดแจ้งจากท่านหรือเพื่อวัตถุประสงค์อื่นตามที่กฎหมายกำหนดไว้เท่านั้น
              ทั้งนี้ บริษัทฯ
              จะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการรักษาความปลอดภัยที่เพียงพอเพื่อปกป้องคุ้มครองข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b> 6. การเก็บรวบรวม และการได้รับข้อมูลส่วนบุคคลของท่าน</b>
          <ul>
            <li>
              (1) บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรง
              และ/หรือจากแหล่งข้อมูลอื่นที่ไม่ใช่จากเจ้าของข้อมูลโดยตรง เช่น
              ข้อมูลที่เผยสู่สารธารณะ ผู้ให้บริการภายนอก นายทะเบียนหลักทรัพย์
              เป็นต้น ทั้งนี้
              ข้อมูลที่ได้รับมาจากแหล่งอื่นจะได้รับการตรวจสอบหรือรับรองแล้วว่าสามารถนำมาใช้ได้ตามวัตถุประสงค์ของนโยบายฉบับนี้
            </li>
            <li>
              (2) บริษัทอาจเข้าถึงข้อมูลเกี่ยวกับความสนใจ และ/หรือความชอบของท่าน
              และ/หรือข้อมูลการเข้าใช้งานเว็บไซต์ต่างๆ
              ของท่านด้วยการใช้คุกกี้ในเว็บไซด์ และแอปพลิเคชันของบริษัท
              โดยคุกกี้ที่อยู่ในเว็บไซด์
              และแอปพลิเคชันของบริษัทจะเก็บข้อมูลของท่านไว้
              และบริษัทจะสามารถเข้าถึงข้อมูลดังกล่าวได้ ทั้งนี้
              เพื่อให้บริษัทสามารถนำเสนอบริการที่ตรงกับความต้องการของท่านให้ได้มากที่สุด
              นอกจากนี้
              เมื่อท่านดาวน์โหลดและติดตั้งแอปพลิเคชันของบริษัทในบริษัทไว้ในมือถือหรืออุปกรณ์อิเล็กทรอนิกส์อื่นใดของท่าน
              บริษัทอาจเข้าถึงข้อมูลต่างๆ
              ที่ท่านมีไว้เพื่อใช้ในการติดต่อระหว่างท่านกับบริษัท
              ไม่ว่าจะเป็นทางโทรศัพท์ อีเมล แอปพลิเคชันของบริษัท
            </li>
          </ul>
        </li>
        <br />
        <br />
      </ul>
      <div class="text-center">
        <div>
          แบบฟอร์มการขอใช้สิทธิข้อมูลส่วนบุคคลสำหรับลูกค้า สามารถ Download
          ได้จาก
        </div>
        <div>
          Link:&nbsp;<span @click="download" class="red--text"
            ><u>
              <a
                style="color: inherit; text-decoration: none"
                href="https://toaecoupon.zwiz.ai/customer_consent_form.pdf"
                download="customerConsentForm"
                >ดาวน์โหลด</a
              >
            </u></span
          >
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.topic {
  padding-left: 0px !important;
}
ul li {
  list-style: none;
}
</style>
