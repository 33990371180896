<template>
  <div style="height: 100%">
    <v-slide-x-transition leave-absolute hide-on-leave>
      <div style="height: 100%; max-width: 500px" class="transition mx-auto"></div>
    </v-slide-x-transition>
  </div>
</template>

<script>
import api from "@/api";
export default {
  async mounted() {
    window.scrollTo(0, 0);
    this.$store.commit("setLoading", true);
    await this.getProfile();
    this.$store.commit("setLoading", false);
  },
  methods: {
    async getProfile() {
      let userId = this.$store.state.userId;
      let IsValid = await api.getProfile(userId);
      if (!IsValid) {
        this.$router.push({ name: "register" });
      } else {
        this.$router.push({ name: "profile" });
      }
    },
  },
};
</script>
