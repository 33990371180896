<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text" style="font-size: 22px">
      กรอกที่อยู่รับของรางวัล
    </div>
    <div
      class="pa-4"
      v-if="type != 'updateinfo'"
      style="cursor: pointer;"
      @click="$router.push({ name: 'productReward' })"
    >
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
    </div>
    <div class="pa-5">
      <ValidationObserver ref="observer">
        <template v-if="(type == 'updateinfo' && !has_ecoupon) || type != 'updateinfo'">
          <div>ชื่อ</div>
          <ValidationProvider rules="required" name="ชื่อ" v-slot="{ errors }">
            <custom-input
              type="text"
              dense
              solo
              v-model="input.firstName"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <div>นามสกุล</div>
          <ValidationProvider rules="required" name="นามสกุล" v-slot="{ errors }">
            <custom-input
              type="text"
              dense
              solo
              v-model="input.lastName"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <div>รหัสไปรษณีย์</div>
          <ValidationProvider name="รหัสไปรษณีย์" rules="required" v-slot="{ errors }">
            <ThaiAddressInput
              :inputtype="'number'"
              @select="onSelectAddress"
              v-model="input.zipcode"
              :type="'zipcode'"
              :errorMessages="errors"
              :texttype="'number'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <div>จังหวัด</div>
          <ValidationProvider name="จังหวัด" rules="required" v-slot="{ errors }">
            <div>
              <ThaiAddressInput
                :inputtype="'text'"
                @select="onSelectAddress"
                v-model="input.province"
                :type="'province'"
                :errorMessages="errors"
                :texttype="'text'"
              ></ThaiAddressInput>
            </div>
          </ValidationProvider>
          <div>อำเภอ</div>
          <ValidationProvider name="อำเภอ" rules="required" v-slot="{ errors }">
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.district"
              :type="'amphoe'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <div>ตำบล</div>
          <ValidationProvider name="ตำบล" rules="required" v-slot="{ errors }">
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.subdistrict"
              :type="'district'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <div>ที่อยู่จัดส่ง</div>
          <ValidationProvider rules="required" name="ที่อยู่จัดส่ง" v-slot="{ errors }">
            <custom-input
              type="text"
              dense
              solo
              v-model="input.address"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <div>เบอร์โทรศัพท์</div>
          <ValidationProvider
            :rules="{
              required: true,
              thai_phonenumber: true,
              digits: 10,
              regex: /^[0][3-9][0-9]{4}[0-9]{4}$/,
            }"
            name="เบอร์โทรศัพท์"
            v-slot="{ errors }"
          >
            <custom-input
              type="tel"
              maxlength="10"
              numberOnly
              dense
              solo
              v-model="input.tel"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <br />
        </template>
        <b>กรุณาแนบภาพบัตรประชาชน</b>
        <div>ภาพบัตรประชาชน</div>
        <ValidationProvider rules="required" name="ใบเสร็จ" v-slot="{ errors }">
          <UploadDocument
            :errors="errors"
            :key="keyupload"
            :showpreview="true"
            v-model="input.idcardImage"
            :max="1"
          >
          </UploadDocument>
        </ValidationProvider>
      </ValidationObserver>
      <v-btn color="main-color" class="white--text mt-16" block @click="onSubmit" rounded large
        >ยืนยัน</v-btn
      >
    </div>

    <v-dialog v-model="showSuccessDialog" width="450px" height="450px" persistent>
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <v-icon color="primary" large class="mb-4" style="font-size: 95px"
              >mdi-check-circle</v-icon
            >
            <div class="mb-4">
              <h3>
                <span class="primary--text">แลกรางวัลสำเร็จ</span
                ><br />กรุณารอการอัปเดตสถานะจัดส่ง<br />จากเจ้าหน้าที่
              </h3>
            </div>
            <v-btn
              color="main-color"
              class="white--text"
              block
              @click="$router.push({ name: 'profile' })"
              rounded
              large
              >ตกลง</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Confirm Dialog -->
    <v-dialog v-model="showConfirmDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <div class="mb-4">
              <h3>ยืนยันการแลกของรางวัลหรือไม่</h3>
            </div>
            <v-btn
              outlined
              color="gray"
              class="mr-2 black--text"
              @click="showConfirmDialog = false"
              rounded
              large
              >ยกเลิก</v-btn
            >
            <v-btn color="main-color" class="white--text" @click="sendReward" rounded large
              >ยืนยัน</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import ThaiAddressInput from "@/components/ThaiAddressInput.vue";
import UploadDocument from "@/components/UploadFile.vue";
export default {
  components: {
    ThaiAddressInput,
    UploadDocument,
  },
  data() {
    return {
      showSuccessDialog: false,
      showConfirmDialog: false,
      input: {
        firstName: "",
        lastName: "",
        zipcode: "",
        province: "",
        district: "",
        subdistrict: "",
        address: "",
        tel: "",
        idcardImage: [],
      },
      keyupload: 0,
      has_ecoupon: false,
      type: null,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.type && this.$route.query.type == "updateinfo") {
      this.type = this.$route.query.type;
      this.has_ecoupon =
        this.$route.query.has_ecoupon && this.$route.query.has_ecoupon == "true" ? true : false;
      //
    } else {
      let cart = this.$store.getters.getCart;
      if (cart && cart.length == 0) {
        this.$router.push({ name: "none" });
      }
    }

    await this.getLatestRequest();

    let userData = this.$store.state.userData;
    this.input.firstName = userData.firstName;
    this.input.lastName = userData.lastName;
  },
  methods: {
    onSelectAddress(address) {
      this.input.district = address.amphoe;
      this.input.province = address.province;
      this.input.subdistrict = address.district;
      this.input.zipcode = address.zipcode;
    },
    onClose() {
      this.showSuccessDialog = false;
      this.$liff.closeWindow();
    },
    async onSubmit() {
      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      this.showConfirmDialog = true;
    },
    async sendReward() {
      this.$store.commit("setLoading", true);
      this.showConfirmDialog = false;
      let data = {
        userid: this.userId,
        pageid: this.pageId,
        first_name: this.input.firstName,
        last_name: this.input.lastName,
        post_code: this.input.zipcode,
        povince: this.input.province,
        district: this.input.district,
        sub_district: this.input.subdistrict,
        address: this.input.address,
        phone_number: this.input.tel,
        identity_image: this.input.idcardImage[0],
        original_score: this.$store.state.originalPoints,
      };

      let result;

      if (this.$route.query.type && this.$route.query.type == "updateinfo") {
        data.rewardId = this.$route.query.rewardId || null;
        data.has_ecoupon =
          (this.$route.query.has_ecoupon && this.$route.query.has_ecoupon == "true") || false;
        try {
          result = await api.FilloutLuckydrawRewardInfo(data);
          this.$store.commit("setLoading", false);
          if (result && result.status) {
            this.showSuccessDialog = true;
          }
        } catch (error) {
          let reason = "";
          if (error.response && error.response.data && error.response.data.message) {
            reason = error.response.data.message;
          } else {
            reason = error.toString();
          }
          this.$swal.fire({ text: reason });
        }
      } else {
        result = await api.SaveRewardRequest(data);
        this.$store.commit("setLoading", false);
        if (result.responsecode == 200) {
          this.showSuccessDialog = true;
        } else {
          this.$swal.fire({ text: result.reason });
        }
      }
    },
    async getLatestRequest() {
      this.$store.commit("setLoading", true);
      let data = {
        pageid: this.pageId,
        userId: this.userId,
      };
      let result = await api.GetLatestRewardCampaignRequest(data);
      this.$store.commit("setLoading", false);
      if (result.responsecode == 200 && result.data) {
        let lastResult = result.data;
        this.input.firstName = lastResult.first_name;
        this.input.lastName = lastResult.last_name;
        this.input.zipcode = lastResult.post_code || "";
        this.input.province = lastResult.province || "";
        this.input.district = lastResult.district || "";
        this.input.subdistrict = lastResult.sub_district || "";
        this.input.address = lastResult.address;
        this.input.tel = lastResult.phone_number;
        if (lastResult.identity_image) this.input.idcardImage.push(lastResult.identity_image);
      }
    },
  },
};
</script>
