<template>
  <MainLayout :showBottomNav="!loading">
    <div v-if="!loading">
      <div
        class="d-flex justify-space-between main-color pa-4 mb-4 white--text"
      >
        <div>
          <div>แต้มสะสม</div>
          <div>
            <span style="font-size: 35px">{{ points }}</span
            >&nbsp;แต้ม
          </div>
        </div>
        <div>
          <v-badge :content="amountOfReward">
            <v-btn icon @click="showCartDialog = true">
              <v-icon color="white">mdi-cart</v-icon></v-btn
            >
          </v-badge>
        </div>
      </div>
      <div class="text-center primary--text mb-4" v-if="selectedCampaign">
        <v-menu
          offset-y
          attach
          rounded
          :disabled="filterCampaigns && filterCampaigns.length == 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="topic mt-10 mb-5" v-bind="attrs" v-on="on">
              {{ selectedCampaign.name }}&nbsp;<v-icon
                v-if="filterCampaigns && filterCampaigns.length > 0"
                color="primary"
                >mdi-chevron-down</v-icon
              >
            </div>
          </template>
          <v-list>
            <v-list-item v-for="fc in filterCampaigns" :key="fc.id">
              <v-list-item-title @click="onChooseCampaign(fc)">{{
                fc.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="mb-2 pl-3" v-if="selectedCategory">
        <v-btn
          :outlined="c.name != selectedCategory.name"
          color="primary"
          v-for="c in categories"
          :key="c.id"
          class="mr-2 mb-2"
          rounded
          @click="onChooseCategory(c)"
          >{{ c.name }}</v-btn
        >
      </div>
      <div>
        <v-layout wrap>
          <v-flex xs6 md6 sm6 v-for="p in products" :key="p.id" class="pa-3">
            <div
              style="height: 190px; border: 1px solid #d9dada; cursor: pointer"
              @click="onChooseProduct(p)"
            >
              <div
                class="text-center pa-3"
                :class="{
                  notEnoughLight: remainingPoints < p.points,
                }"
                style="height: 150px; position: relative"
              >
                <span
                  v-if="points < p.points"
                  class="text-right"
                  style="
                    font-size: 8px;
                    color: gray;
                    position: absolute;
                    top: 0%;
                    right: 5px;
                  "
                  >แต้มไม่เพียงพอ</span
                >
                <img :src="p.photo" width="90px" height="90px" class="mb-1" />
                <div
                  class="primary--text text-center"
                  style="
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ p.name }}
                </div>
              </div>
              <div
                class="
                  text-center
                  white--text
                  d-flex
                  align-center
                  justify-center
                "
                :class="{
                  primary: remainingPoints >= p.points,
                  notEnough: remainingPoints < p.points,
                }"
                style="height: 40px"
              >
                <span>แลก&nbsp;{{ p.points }}&nbsp;แต้ม</span>
              </div>
            </div>
          </v-flex>
        </v-layout>
        <infinite-loading
          spinner="bubbles"
          :identifier="infiniteId"
          @infinite="infiniteHandler"
        >
          <span slot="no-more"></span> <span slot="no-results"></span
        ></infinite-loading>
        <div
          v-if="products.length == 0 && IsstateComplete"
          style="margin-top: 30%; text-align: center"
        >
          <h2 style="color: #979696">ไม่พบของรางวัล</h2>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDetailDialog"
      fullscreen
      persistent
      no-click-animation
    >
      <v-card>
        <product-reward-detail
          v-if="showDetailDialog"
          :detail="rewardDetail"
          @onClose="onCloseDetailDialog"
        ></product-reward-detail>
      </v-card>
    </v-dialog>

    <v-dialog
      no-click-animation
      persistent
      v-model="showCartDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <Cart
          v-if="showCartDialog"
          @onBack="showCartDialog = false"
          @onClose="showCartDialog = false"
        ></Cart
      ></v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" width="450px" height="450px">
      <v-card>
        <div style="display: flex; justify-content: center; text-align: center">
          <div style="width: 350px; margin: 30px">
            <div class="mb-4">
              <h3>
                หากเปลี่ยนแคมเปญ ของรางวัลที่เพิ่มไว้จะถูกลบออก
                ยืนยันที่จะเปลี่ยนแคมเปญหรือไม่
              </h3>
            </div>
            <v-btn
              outlined
              color="gray"
              class="mr-2 black--text"
              @click="showConfirmDialog = false"
              rounded
              large
              >ยกเลิก</v-btn
            >
            <v-btn
              color="main-color"
              class="white--text"
              @click="deleteCart"
              rounded
              large
              >ยืนยัน</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </MainLayout>
</template>

<script>
import api from "@/api";
import InfiniteLoading from "vue-infinite-loading";
import ProductRewardDetail from "./ProductRewardDetail.vue";
import Cart from "./Cart.vue";
export default {
  components: {
    InfiniteLoading,
    ProductRewardDetail,
    Cart,
  },
  data() {
    return {
      loading: false,
      limit: 15,
      offset: 0,
      selectedCategory: null,
      selectedTemporaryCampaign: null,
      selectedCampaign: null,
      campaigns: [],
      categories: [],
      products: [],
      infiniteId: +new Date(),
      IsstateComplete: false,
      state: null,
      showDetailDialog: false,
      rewardDetail: null,
      showCartDialog: false,
      showConfirmDialog: false,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.$store.commit("setLoading", true);
    await this.getCampaigns();
    if (this.campaigns && this.campaigns.length > 0) {
      await this.getPointsByCampaignId();
      await this.getCatogories();
      await this.GetMyCart();
    }
    this.loading = false;
    this.$store.commit("setLoading", false);
  },
  computed: {
    points() {
      return this.$store.state.originalPoints;
    },
    remainingPoints() {
      return this.$store.state.remainingPoints;
    },
    filterCampaigns() {
      let result = [];
      if (this.selectedCampaign) {
        result = this.campaigns.filter((f) => f.id != this.selectedCampaign.id);
      }
      return result;
    },
    cart() {
      return this.$store.getters.getCart;
    },
    amountOfReward() {
      let amount = 0;
      let cart = this.$store.getters.getCart;
      if (cart && cart.length > 0) {
        amount = cart
          .map((m) => m.quantity)
          .reduce((prev, curr) => prev + parseInt(curr));
      }
      return amount;
    },
  },
  methods: {
    async onChooseCampaign(campaign) {
      if (campaign.id == this.selectedCampaign.id) {
        return;
      }

      this.selectedTemporaryCampaign = campaign;

      if (this.cart && this.cart.length > 0) {
        this.showConfirmDialog = true;
        return;
      }

      await this.onChangeCampaign();
    },
    async onChooseCategory(category) {
      this.products = [];
      this.selectedCategory = category;
      this.offset = 0;
      this.infiniteId += 1;
    },
    onChooseProduct(product) {
      this.rewardDetail = product;
      this.showDetailDialog = true;
    },
    async getCampaigns() {
      let result = await api.getCampaignRewards({ pageid: this.pageId });
      if (result.responsecode == 200) {
        this.campaigns =
          result.data && result.data.length > 0
            ? result.data.filter((f) => f.type == "collect")
            : [];
        this.selectedCampaign =
          this.campaigns && this.campaigns.length > 0
            ? this.campaigns[0]
            : null;
      }
    },
    async getCatogories() {
      let data = {
        campaignId: this.selectedCampaign.id,
        pageid: this.pageId,
        source: "liff",
      };
      let result = await api.getRewardCategoriesByCampaignId(data);
      if (result.responsecode == 200) {
        this.categories = [
          {
            name: "ทั้งหมด",
            id: 0,
          },
        ];
        this.categories.push(...result.data);
        // Set default catogory as all
        this.selectedCategory = this.categories[0];
      }
    },
    async getRewardsByCampaignIdAndCategoryId() {
      this.products = [];
      let data = {
        pageid: this.pageId,
        userid: this.userId,
        limit: this.limit,
        offset: this.offset,
        campaign_id: this.selectedCampaign.id,
        category_id:
          this.selectedCategory && this.selectedCategory.id != 0
            ? this.selectedCategory.id
            : null,
      };
      let result = await api.getRewardByCampaignIdAmdCategory(data);
      if (result.responsecode == 200) {
        let rawData =
          result.data && result.data.length > 0
            ? result.data.filter((f) => f.quantity != 0)
            : [];
        this.products = rawData;
      }
    },
    async getPointsByCampaignId() {
      let data = {
        userid: this.userId,
        pageid: this.pageId,
        campaign_id: this.selectedCampaign ? this.selectedCampaign.id : null,
      };
      let result = await api.getPointsByCampaignId(data);
      if (result.responsecode == 200) {
        this.$store.state.originalPoints = result.points;
        this.$store.state.remainingPoints = result.points;
      }
    },
    async infiniteHandler(state) {
      this.IsstateComplete = false;
      this.state = state;
      if (!this.selectedCampaign) {
        this.state.complete();
        this.IsstateComplete = true;
        return;
      }
      var length = await this.getRewardsByCampaignIdAndCategoryId();
      this.offset += 15;
      if (length) {
        this.state.loaded();
      } else {
        this.state.complete();
        this.IsstateComplete = true;
      }
    },
    async GetMyCart() {
      let IsMatchCurrentCampaign = await this.CheckExistingCartByCampaignId();
      if (!IsMatchCurrentCampaign) {
        this.$store.commit("clearCart");
        return;
      }

      let data = {
        userid: this.userId,
        pageid: this.pageId,
      };
      let result = await api.getMyCart(data);
      if (result.responsecode == 200 && result.data && result.data.length > 0) {
        // Reformat
        let rewards = [];
        result.data.forEach((d) => {
          rewards.push({
            ...d,
            name: d.productname,
            points: d.cartprice / d.quantity,
          });
        });
        this.$store.dispatch("setCart", rewards);
      }
    },
    onCloseDetailDialog() {
      this.showDetailDialog = false;
      this.rewardDetail = null;
    },
    async deleteCart() {
      this.showConfirmDialog = false;
      this.$store.commit("setLoading", true);
      let data = {
        userid: this.userId,
        pageid: this.pageId,
      };
      let result = await api.DeleteCart(data);
      if (result.responsecode != 200) {
        this.$swal.fire({ text: result.reason });
      }
      this.$store.commit("clearCart");
      this.$store.commit("setLoading", false);
      this.onChangeCampaign();
    },
    async onChangeCampaign() {
      this.products = [];
      this.selectedCampaign = this.selectedTemporaryCampaign;
      this.$store.commit("setLoading", true);
      this.offset = 0;
      this.infiniteId += 1;
      await this.getPointsByCampaignId();
      await this.getCatogories();
      await this.GetMyCart();
      this.$store.commit("setLoading", false);
    },
    async CheckExistingCartByCampaignId() {
      let status = false;
      let data = {
        userid: this.userId,
        pageid: this.pageId,
        campaign_id: this.selectedCampaign.id,
      };
      let result = await api.CheckExistingCartByCampaignId(data);
      if (result.responsecode == 200) {
        if (result.status) {
          status = true;
        }
      }
      return status;
    },
  },
};
</script>

<style scoped>
.notEnough {
  background: #9c9a9a;
}
.notEnoughLight {
  background: #eeecec;
}
</style>
