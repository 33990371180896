<template>
  <MainLayout>
    <div class="main-color pa-4 text-center white--text mb-7" style="font-size: 22px">
      ของรางวัลที่ต้องการแลก
    </div>
    <div class="pa-4" @click="onBack"  style="cursor: pointer;">
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span class="primary--text">กลับ</span>
    </div>
    <div v-if="cart && cart.length > 0">
      <div class="pa-4 d-flex mb-4" v-for="(c, index) in cart" :key="index" style="height: 170px">
        <div
          style="border: 1px solid #f5f5f0; height: 170px; width: 160px"
          class="d-flex align-center justify-center"
        >
          <img :src="c.photo" width="140px" height="140px" />
        </div>
        <div class="pl-5">
          <div class="pt-5">
            <b>{{ c.name }}</b>
          </div>
          <div class="mb-3">
            <b class="primary--text">แลก&nbsp;{{ c.points }}&nbsp;แต้ม</b>
          </div>
          <CartButton :reward="c" v-model="c.quantity" :IsAddToCart="true"></CartButton>
        </div>
      </div>
      <div class="text-right primary--text pa-4">
        <div>แต้มที่ใช้หมด&nbsp;:&nbsp;{{ requirePoints }}&nbsp;แต้ม</div>
        <div>แต้มคงเหลือ&nbsp;:&nbsp;{{ remainingPoints }}&nbsp;แต้ม</div>
      </div>
      <div class="pa-4">
        <v-btn
          color="main-color"
          class="white--text mb-4"
          block
          @click="onRewardAction"
          rounded
          large
          >แลกของรางวัล</v-btn
        >
        <v-btn color="main-color" outlined block @click="onChooseReward" rounded large
          >เลือกของรางวัลต่อ</v-btn
        >
      </div>
    </div>
    <div v-else class="gray--text" style="margin-top: 30%; text-align: center">
      ตะกร้าว่าง<br />
      <div class="pa-4 mt-16">
        <v-btn color="main-color" outlined block @click="onChooseReward" rounded large
          >เลือกของรางวัลต่อ</v-btn
        >
      </div>
    </div>
  </MainLayout>
</template>

<script>
import api from "@/api";
import CartButton from "@/components/CartButton.vue";
export default {
  components: {
    CartButton,
  },
  data() {
    return {
      loading: false,
      CheckedOutOfStock: false,
    };
  },
  computed: {
    cart() {
      return this.$store.getters.getCart;
    },
    requirePoints() {
      return this.$store.state.requirePoints;
    },
    remainingPoints() {
      return this.$store.state.remainingPoints;
    },
    IscartLoading() {
      return this.$store.state.cartLoading;
    },
  },
  async mounted() {
    if (this.cart.length > 0) {
      await this.CheckOutOfStock();
    }
  },
  methods: {
    onRewardAction() {
      this.$router.push({ name: "addressForReward" });
    },
    onChooseReward() {
      this.$emit("onClose");
    },
    async CheckOutOfStock() {
      this.$store.commit("setLoading", true);
      let data = {
        pageid: this.pageId,
        userid: this.userId,
      };
      let result = await api.CheckOutOfStock(data);
      this.CheckedOutOfStock = true;
      this.$store.commit("setLoading", false);
      if (result.responsecode == 204) {
        console.log(result.productIdsOutOfStock);
        this.$swal.fire({ text: result.reason });
      }
    },
    onBack() {
      this.CheckedOutOfStock = false;
      this.$emit("onBack");
    },
  },
};
</script>
