/* eslint-disable no-irregular-whitespace */
<template>
  <MainLayout>
    <div
      class="pa-4 text-center white--text main-color"
      style="font-size: 22px"
    >
      ข้อตกลงและเงื่อนไข
    </div>
    <div class="pa-4" style="cursor: pointer" @click="$router.go(-1)">
      <v-icon color="primary">mdi-arrow-left</v-icon>&nbsp;<span
        class="primary--text"
        >กลับ</span
      >
    </div>
    <div
      class="ma-2 pa-4 topic"
      style="background-color: #eeeeee; border-radius: 5px"
    >
      <ol class="mx-2">
        <li>
          กิจกรรม : TOA-CLUB สะสมยอดซื้อ แลกได้เลย ไม่ต้องลุ้น (1 บาท เท่ากับ 1
          แต้ม)
        </li>
        <li>
          วิธีการลงทะเบียนเข้าร่วมกิจกรรม :
          <ol>
            <li>
              เพิ่มเพื่อนและลงทะเบียนผ่านทาง Application Line Official Account :
              @TOA-CLUB
            </li>
            <li>
              สมัครสมาชิก กรอก ชื่อ - นามสกุล, เบอร์โทรศัพท์ ให้ครบถ้วนถูกต้อง
            </li>
            <li>
              ผู้ขอใช้บริการสามารถลงทะเบียนเข้าใช้งาน
              ได้โดยจะต้องทำการให้รายละเอียดข้อมูลตามที่บริษัทกำหนด
              รวมทั้งได้รับอนุมัติจากบริษัทฯ ให้เป็นสมาชิก
              โดยผู้ใช้บริการต้องผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขในการใช้บริการ
            </li>

            <li>
              สมาชิกรับรองว่าข้อมูลที่ได้ให้ไว้แก่บริษัทฯ
              ในการลงทะเบียนนี้ถูกต้องครบถ้วน
              โดยเฉพาะอย่างยิ่งรับรองว่าเบอร์โทรศัพท์มือถือยังใช้งานอยู่ในขณะที่ได้ให้ข้อมูลดังกล่าว
            </li>

            <li>
              สินค้า TOA ที่เข้าร่วมรายการ กลุ่มเคมีภัณฑ์ก่อสร้าง
              ทุกรายการสินค้า
            </li>

            <li>
              ใบเสร็จ ที่เข้าร่วมกิจกรรม คือ ใบเสร็จรับเงิน / ใบกำกับภาษี /
              ใบกำกับภาษีอย่างย่อ / บิลเงินสดที่มีชื่อ ที่อยู่
              เลขประจำตัวผู้เสียภาษี ของร้านค้า, ชื่อลูกค้า, เลขที่บิล,
              วันที่ซื้อขาย, มีลายเซ็นผู้รับเงิน ตรงกับ หนังสือบริคณห์สนธิ พร้อม
              ตราประทับร้านค้า โดยรายละเอียดในใบเสร็จต้องมี
              ที่มีชื่อและที่อยู่ร้านค้า, เลขประจำตัวผู้เสียภาษีของร้านค้า,
              เลขที่ใบเสร็จ, มูลค่าและสินค้าที่ซื้อ และวันที่ซื้อได้อย่างชัดเจน
            </li>
            <li>
              ถ่ายรูปใบเสร็จ และ อัปโหลดใบเสร็จผ่านทาง Appilcation Line Official
              Account : @TOA-CLUB ( 1 เลขที่ใบเสร็จ / 1 ครั้ง )
            </li>
            <li>
              ในกรณี ใบเสร็จตรงตามเงื่อนไขข้างต้น จะได้รับแต้มสะสม (1 บาท
              เท่ากับ 1 แต้ม) เพื่อแลกของรางวัล ผ่านทาง Application Line
              Official Account : @TOA-CLUB
            </li>
            <li>
              ทางบริษัทจะอนุมัติใบเสร็จที่เข้าร่วมกิจกรรมและอัปเดทคะแนน ภายใน 2
              - 3 วันทำการ หลังจากที่รับข้อมูลที่ถูกต้องครบถ้วน
            </li>
            <li>
              บริษัทฯ ขอสงวนสิทธิในการยกเลิกการเป็นสมาชิก
              หรือเปลี่ยนแปลงเงื่อนไขสิทธิประโยชน์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
            </li>
            <li>
              กิจกรรมนี้เฉพาะ สมาชิก End User เท่านั้น
              ร้านค้าที่เป็นตัวแทนจำหน่ายสี ทีโอเอ ไม่สามารถเข้าร่วมรายการนี้ได้
              หากตรวจพบว่ามีการทุจริต ขอระงับการเป็นสมาชิก
              และไม่จ่ายผลประโยชน์ทั้งสิ้น โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
            </li>
          </ol>
        </li>
        <li>
          การคำนวณแต้ม :
          <ol>
            <li>
              ทุกๆ การซื้อสินค้า ทีโอเอ กลุ่มเคมีภัณฑ์ก่อสร้าง 1 บาท เท่ากับ 1
              แต้ม เท่านั้น สำหรับยอดเงินที่เป็นเศษสตางค์ “ไม่”
              สามารถสะสมแต้มได้
            </li>
            <li>
              แต้มพิเศษ ซื้อสินค้า TOA 201 Roof Seal ภายในบิล ขนาด 1 Kg.
              รับเพิ่ม 5 แต้ม , ขนาด 4 Kg. รับเพิ่ม 10 แต้ม , ขนาด 20 Kg.
              รับเพิ่ม 20 แต้ม
            </li>
          </ol>
        </li>
        <li>
          ของรางวัล รายละเอียด ดังนี้ :
          <ol>
            <li>
              สะสมยอดซื้อครบ 450,000 แต้ม แลกรับ โทรทัศน์ Samsung Crystal UHD 4K
              SMART TV 43" รุ่น UA43DU700 จำนวนจำกัด 5 รางวัล
            </li>
            <li>
              สะสมยอดซื้อครบ 130,000 แต้ม แลกรับ ตู้เย็นมินิบาร์ Midea 1.6 คิว
              รุ่น HS-65LN จำนวนจำกัด 20 รางวัล
            </li>
            <li>
              สะสมยอดซื้อครบ 70,000 แต้ม แลกรับ เครื่องดูดฝุ่น ดูดน้ำ เป่าลม
              Imarflex รุ่น VC-937 จำนวนจำกัด 40 รางวัล
            </li>
            <li>
              สะสมยอดซื้อครบ 30,000 แต้ม แลกรับ พัดลม IMARFLEX 16" รุ่น IF-966
              จำนวนจำกัด 100 รางวัล
            </li>
            <li>
              สะสมยอดซื้อครบ 20,000 แต้ม แลกรับ กระทะไฟฟ้า SMARTHOME รุ่น SFP800
              จำนวนจำกัด 100 รางวัล
            </li>
          </ol>
        </li>
        <li>
          รายการสินค้าเข้าร่วม คือ สินค้า ทีโอเอ
          <ol>
            <li>
              กลุ่มเคมีภัณฑ์ก่อสร้าง : TOA 100 WATER REPELLENT GLOSS , TOA 101
              DECORPLAST , TOA 102 D-COTE , 4 SEASONS D-COTE , TOA 110 SKIM COAT
              SMOOTH , TOA 113 MICRO KILL , TOA 200 FLOORING PLUS WATER
              REPELLENT GLOSS WB , TOA 201 ROOFSEAL , TOA 213 WATER REPELLENT WB
              , TOA 214 WATER REPELLENT SB , TOA 302 ACRYLIC SEALANT , TOA 303
              ACRYLIC SEALANT , TOA ACRYLIC FILLER , TOA BITUMINOUS PRIMER , TOA
              BITUSHEET SELF-ADHESIVE , TOA BONDING AGENT , TOA CEMENT
              WATERPROOF 1 PART , TOA CEMENT WATERPROOF 2 PART (PART B) 20 KG ,
              TOA CEMENT WATERPROOF 2 PART 6 KG , TOA CRACK PROOF , TOA FAST
              BOND , TOA FIBER MESH , TOA FLOOR HARDENER , TOA GROUT (500KSC) ,
              TOA GROUT GP , TOA MOISTURE GUARD , TOA MORTARPLAST , TOA MS
              SEALANT , TOA Premium Grout , TOA Premium Grout Plus , TOA
              PREMIUMTILE , TOA PROTILE , TOA SUPERTILE , TOA PU FOAM , TOA PU
              SEALANT , TOA PU WATERPROOF , TOA REBAR ANCHORING , TOA ROOFSEAL
              SUN BLOCK , TOA SILICONE SEALANT (GP) , TOA SILICONE SEALANT (HP)
              , TOA SILICONE SEALANT (HP+) , TOA SKIM COAT SMOOTH , TOA SMART
              TAPESEAL-BM , TOA WALLSHIELD , TOA WATER BLOC , TOA WATERPROOF
              ADMIXTURE
            </li>
          </ol>
        </li>
        <li>
          ระยะเวลากิจกรรม :
          <ol>
            <li>
              สามารถอัปโหลดใบเสร็จ สะสมได้ตั้งแต่วันที่ 01 ตุลาคม 2567 เวลา 0.01
              น. - 30 พฤศจิกายน 2567 เวลา 23.59 น. โดยบนใบเสร็จต้องระบุวันที่
              ที่ทำการซื้อสินค้าเข้าร่วมรายการ เริ่มตั้งแต่วันที่ 01 ตุลาคม 2567
              เท่านั้น
              ขอสงวนสิทธิ์ในการปฏิเสธใบเสร็จที่ทำการซื้อสินค้าก่อนวันที่ 01
              ตุลาคม 2567 หรือ หลัง วันที่ 30 พฤศจิกายน 2567
            </li>
            <li>
              สามารถแลกรางวัล จากคะแนนสะสมได้ ตั้งแต่วันที่ 01 ตุลาคม 2567 เวลา
              0.01 น. - 15 ธันวาคม 2567 เวลา 23.59 น.
            </li>
            <li>
              หากบริษัทฯ ตรวจพบว่ามีเจตนาทุจริต ยกเลิก หรือขอคืนสินค้าภายหลัง
              ขอสงวนสิทธิ์ในการระงับการอนุมัติใบเสร็จทันที
            </li>
          </ol>
        </li>
        <li>
          ร้านค้าที่เข้าร่วมรายการ
          <ol>
            <li>
              ร้านค้าตัวแทนจำหน่าย TOA ที่เข้าร่วมรายการ เท่านั้น
              ติดต่อสอบถามได้ที่ร้านค้าตัวแทนจำหน่าย TOA (ยกเว้นร้านค้า Mega
              Paint ไม่เข้าร่วมรายการ)
            </li>
          </ol>
        </li>
        <li>
          เงื่อนไขการแลกรับของรางวัล :
          <ol>
            <li>
              ผู้ร่วมกิจกรรมต้องรับทราบและยินยอมปฏิบัติตามกติกา
              เงื่อนไขและข้อตกลงการเข้าร่วมกิจกรรมฉบับนี้โดยไม่มีข้อโต้แย้งใด ๆ
              รวมถึงข้อกำหนดในการใช้งานของทางแอปพลิเคชั่นไลน์
            </li>
            <li>
              พนักงานและครอบครัวของพนักงาน บริษัท ทีโอเอ เพ้นท์ (ประเทศไทย)
              จำกัด (มหาชน) บริษัทในเครือ บริษัทตัวแทนโฆษณา
              คณะกรรมการดำเนินรายการ และบริษัทที่มีส่วนเกี่ยวข้องในการดำเนิน
              การจัดรายการในครั้งนี้ไม่มีสิทธิ์เข้าร่วมรายการ
            </li>
            <li>
              แต้มสะสมและของรางวัลที่ได้รับไม่สามารถ แลก ทอน เปลี่ยนเป็นเงินสด
              หรือของรางวัลอื่นได้
              และไม่สามารถโอนสิทธิ์ให้กับบุคคลภายนอกหรือนิติบุคคลอื่นได้
              ไม่ว่ากรณีใด รวมทั้งไม่มีการจ่ายเงินเป็นส่วนประกอบเเต่อย่างใด
            </li>
            <li>
              บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดต่างๆ
              เกี่ยวกับของรางวัล โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              ทั้งนี้ของรางวัลใหม่นั้นจะมีมูลค่าเทียบเท่าของรางวัลเดิม
            </li>
            <li>
              บริษัทฯ
              มิได้มีส่วนเกี่ยวข้องในการจัดจำหน่ายของรางวัลที่ระบุในรายการนี้
              บริษัทฯ จะไม่รับประกันและไม่รับผิดชอบต่อคุณภาพ
              หรือบริการที่อาจเกิดขึ้นกับรางวัลดังกล่าว
              หากมีปัญหาเกี่ยวกับคุณภาพ หรือบริการ
              ผู้โชคดีจะต้องติดต่อที่บริษัทฯ ที่จำหน่ายหรือให้บริการโดยตรง
            </li>
            <li>
              บริษัทฯ จะไม่รับผิดชอบต่อระบบอินเตอร์เน็ตที่ล่าช้า ไม่สมบูรณ์
              หรือเกิดจากการฉ้อโกงหรือการส่งข้อมูลที่ไม่ถูกต้อง
              ที่เกิดจากผู้เข้าร่วมรายการหรือระบบอินเตอร์เน็ตที่ล่าช้า
              ไม่ว่าจะเนื่องมาจากสาเหตุของความผิดพลาด การเพิกเฉย
              การแก้ไขปรับเปลี่ยน การให้สินบน การลบทิ้ง ขโมย
              การทำลายข้อมูลโดยไม่ได้รับอนุญาตหรือการลักลอบใช้ข้อมูล
              ความเสียหายของข้อมูล เครือข่ายล้มเหลว
              หรือความผิดพลาดของซอฟต์แวร์หรือฮาร์ดแวร์ หรือไม่ว่าเหตุใด
            </li>
            <li>
              ผู้เข้าร่วมกิจกรรม ต้องสะสมแต้ม แลกของรางวัลภายในวันที่ 15 ธันวาคม
              2567 เวลา 23.59 น. หากเลยระยะเวลาวันดังกล่าว
              ถือว่าสะสิทธิ์ในการแลกของรางวัล
            </li>
            <li>
              ของรางวัล บริษัทฯ จะส่งไปให้ตามที่อยู่ ที่ลูกค้าแจ้งเข้ามาในระบบ
              ภายในระยะเวลา 7 - 14 วันทำการ
              นับจากวันที่ผู้เข้าร่วมกิจกรรมกดแลกของรางวัล บริษัทฯ
              ขอสงวนสิทธิไม่ส่งของรางวัลใหม่ในกรณีที่แจ้งที่อยู่ผิด
              <br />
              รอบจัดส่ง แบ่งเป็น 2 รอบต่อเดือน : รอบที่ 1 แลกของรางวัล
              ตัดรอบตั้งแต่ วันที่ 01 - 15 ของเดือน / รอบที่ 2 แลกของรางวัล
              ตัดรอบตั้งแต่ วันที่ 16 - 31 ของเดือน จัดส่งในวันถัดไป
            </li>
            <li>
              แต้มที่ใช้แลกแล้ว จะไม่สามารถนำกลับมาใช้ได้อีก เช่น
              แต้มที่แลกรับของรางวัลแล้ว จะไม่สามารถนำมาแลกรางวัลได้อีก
              และไม่สามารถขอคืนได้ไม่ว่ากรณีใดๆ ทั้งสิ้น
              โดยหากมีคะแนนสะสมเหลือหลังพ้นช่วงระยะเวลาร่วมรายการนี้แล้ว
              จะถือว่าแต้มนั้นหมดอายุทันที ไม่สามารถนำคะแนนไปรวมกับ
              กิจกรรมอื่นที่ทางบริษัทจัดได้อีก
            </li>
          </ol>
        </li>
        <li>
          เงื่อนไขวันหมดอายุแต้ม
          <ol>
            <li>
              แต้มที่สะสมจะหมดอายุใน วันที่ 15 ธันวาคม 2567 เวลา 23.59 น.
            </li>
          </ol>
        </li>
        <li>
          การปรับเปลี่ยนข้อมูลส่วนตัวของสมาชิก
          <ol>
            <li>
              สมาชิกสามารถทำการปรับเปลี่ยนข้อมูล ผ่านทาง Application Line
              Official Account : @TOA-CLUB
            </li>
            <li>
              สมาชิกรับรองว่า หากมีการเปลี่ยนแปลงข้อมูลส่วนตัวของสมาชิก
              สมาชิกจะปรับปรุงข้อมูลส่วนตัวของสมาชิกให้ทันสมัยอยู่ตลอดเวลา
            </li>
          </ol>
        </li>
        <li>
          ติดตามรายละเอียดและสอบถามข้อมูลเพิ่มเติมได้ที่ Application Line
          Official Account : @TOA-CLUB วันจันทร์ - ศุกร์ เวลา 09.00 น. - 17.00
          น. ยกเว้นวันหยุดนักขัตฤกษ์
        </li>
      </ol>
    </div>
  </MainLayout>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.topic {
  padding-left: 0px !important;
}
ul li {
  list-style: none;
}

.topic ol {
  counter-reset: item;
  & > li {
    margin-bottom: 20px;

    & li {
      margin-bottom: 15px;
    }
  }

  li {
    display: block;
  }
  li:before {
    content: counters(item, ".") ".";
    counter-increment: item;
  }
}
</style>
